import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTabGroup } from '@angular/material/tabs';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-search-matrix-popup',
  templateUrl: './search-matrix-popup.component.html',
  styleUrls: ['./search-matrix-popup.component.scss']
})
export class SearchMatrixPopupComponent implements OnInit {

  address = new FormControl('');

  @ViewChild('packagingElm') packagingElm: ElementRef;
  @ViewChild('productElm') productElm: ElementRef;
  @ViewChild('distanceElm') distanceElm: ElementRef;
  @ViewChild('company') company: MatSelect;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('truckRadiusElm') truckRadiusElm: MatSelect;
  @ViewChild('distanceRadiusElm') distanceRadiusElm: MatSelect;
  @ViewChild('radiusAddress') radiusAddress: ElementRef;

  packagingArr;
  productsArr;
  clientsArr;
  companyArr;

  constructor(public dialogRef: MatDialogRef<SearchMatrixPopupComponent>,
    private dataService: DataService) {}

  ngOnInit(): void {

    var autocompleteInterval = setInterval(() => {
      this.initializeAutocompletes();
      clearInterval(autocompleteInterval);
    }, 1000);

    this.dataService.getaccounts.subscribe(elms => {
      this.clientsArr = elms;
      if(this.clientsArr) this.clientsArr.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    });
    this.dataService.getpackaging.subscribe(elms => {
      this.packagingArr = elms;
      if(this.packagingArr) this.packagingArr.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    });

    this.dataService.getcommodities.subscribe(elms => {
      this.productsArr = elms;
      if(this.productsArr) this.productsArr.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    });
  }

  initializeAutocompletes() {

  }

  onSubmitSearch() {
    var params: any = {};
    var tabIndex = this.tabGroup.selectedIndex;

    if(tabIndex === 0) {
      Object.assign(params, {searchType: 'matrix'});
      if(this.packagingElm['_value']) Object.assign(params, {Packaging: this.packagingElm['_value']});
      if(this.productElm['_value']) Object.assign(params, {Product: this.productElm['_value']});
      if(this.distanceElm['_value']) Object.assign(params, {Distance: this.distanceElm['_value']});
      if(this.company.value) Object.assign(params, {Company: this.company.value});

      //Close dialog and emit data
      if(params.Packaging || params.Product || params.PickupClient
         || params.DropoffClient || params.Distance || params.Company) {
        this.dialogRef.close(params);
      }
    } else {
      Object.assign(params, {searchType: 'radius'});
      if(this.distanceRadiusElm.value) Object.assign(params, {radius: this.distanceRadiusElm.value});
      if(this.truckRadiusElm.value) Object.assign(params, {truck: this.truckRadiusElm.value});
      if(this.radiusAddress.nativeElement.value) Object.assign(params, {location: this.radiusAddress.nativeElement.value});

      if(params.radius && params.truck && params.location ) {
        this.dialogRef.close(params);
      }
    }
  }

}
