<!-- Navigation -->
<treo-vertical-navigation class="theme-dark"
                          [appearance]="'classic'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="navigations"
                          [opened]="false">

    <div treoVerticalNavigationContentHeader>

        <!-- Actions -->
        <div class="actions"
        style="display: grid; padding-bottom: 0 !important;">

            <!-- Logo -->
            <div class="logo" style="width: 100%;height: 118px;transform: scale(0.7);">

            </div>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                id="menu-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <div id="mapOptions">
            <button mat-icon-button  *ngIf="isOnMap"
            (click)="openSearchDialog()"
            matTooltip="Search Routes">
                <mat-icon [svgIcon]="'search'"></mat-icon>
            </button>
            <button mat-icon-button *ngIf="isOnMap"
            (click)="openOverlapDialog()"
              matTooltip="Calculate Overlaps">
                 <mat-icon [svgIcon]="'compare_arrows'"></mat-icon>
            </button>

            <button mat-icon-button *ngIf="isOnMap"
            (click)="toggleRuler()"
            matTooltip="Toggle Ruler">
                <mat-icon [svgIcon]="'straighten'"></mat-icon>
            </button>

            <button mat-icon-button *ngIf="isOnMap"
            (click)="clearLayers()"
            matTooltip="Clear All">
                <mat-icon [svgIcon]="'layers_clear'"></mat-icon>
            </button>


            <button mat-icon-button  *ngIf="isOnMap"
             (click)="openConsumerOverlay()"
             matTooltipPosition="below"
             matTooltip="Consumers">
                <mat-icon [svgIcon]="'group'"></mat-icon>
            </button>

            <div id="consumerPopup" *ngIf="consumerPopopToggled">
                <div class="arrow-up"></div>
                <h1 style="margin: auto;font-size: 18px;color: #fff;">Consumers</h1>
                <p style="margin-bottom: 15px;color: #fff;">Select a type to overlay onto map</p>
                <mat-divider style="width: 100%; margin-bottom: 15px;"></mat-divider>

                <ul>
                    <li *ngFor="let consumer of consumers"
                    style="padding: 5px;">
                        <mat-checkbox
                        (change)="selectConsumer(consumer)">
                            <p style="font-size: 13px;font-weight: 500;">{{consumer.Description}} ({{consumer.count}})</p>
                        </mat-checkbox>
                    </li>
                </ul>
                <mat-divider style="width: 100%; margin-bottom: 15px;  margin-top: 15px;"></mat-divider>

                <button mat-flat-button
                (click)="overlayConsumers()">Search</button>
                <button mat-flat-button
                (click)="clearConsumers()"
                style="margin-left: 15px">Clear</button>
            </div>

            <button mat-icon-button  *ngIf="isOnMap"
            (click)="openManualOverlap()"
             matTooltipPosition="below"
             matTooltip="Manual Overlap">
                <mat-icon [svgIcon]="'arrow_right'"></mat-icon>
            </button>

            <button mat-icon-button  *ngIf="isOnMap"
            (click)="openQuarryOverlay()"
             matTooltipPosition="below"
             matTooltip="Quarries">
                <mat-icon [svgIcon]="'home_work'"></mat-icon>
            </button>

            <div id="quarryPopup" *ngIf="quarryPopopToggled">
                <div class="arrow-up2"></div>
                <h1 style="margin: auto;font-size: 18px;color: #fff;">Quarries</h1>
                <p style="margin-bottom: 15px;color: #fff;">Select a type to overlay onto map</p>
                <mat-divider style="width: 100%; margin-bottom: 15px;"></mat-divider>

                <ul>
                    <li *ngFor="let quarry of quarries"
                    style="padding: 5px;">
                        <mat-checkbox
                        (change)="selectQuarry(quarry)">
                            <p style="font-size: 13px;font-weight: 500;">{{quarry.Description}} ({{quarry.count}})</p>
                        </mat-checkbox>
                    </li>
                </ul>

                <mat-divider style="width: 100%; margin-bottom: 15px;  margin-top: 15px;"></mat-divider>

                <button mat-flat-button
                (click)="overlayQuarries()">Search</button>
                <button mat-flat-button
                 (click)="clearQuarries()"
                 style="margin-left: 15px">Clear</button>
            </div>


        </div>

    </div>

    <!-- Content -->
    <div class="content" style="z-index: 1;">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

</div>
