import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { DbService } from 'src/app/services/db/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';

@Injectable()
export class AuthService
{
    // Private
    public _authenticated: boolean;
    public _userType: number = 0;
    public _user: any;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private dbService: DbService,
        private _snackBar: MatSnackBar,
        private ngzone: NgZone,
        private router: Router,
        private dataService: DataService
    )
    {
        // Set the defaults
        this._authenticated = true;
/*
        var token = localStorage.getItem('access_token');
        if(token != null) {
            var fetchedToken = this.getToken();
            if(fetchedToken != null) {
                this.dbService.loginUser(fetchedToken).subscribe( (res: any) => {
                    if(res != false) {
                        this._user = res;
                        localStorage.setItem('user', res.Name);
                        localStorage.setItem('email', res.Email);

                        this._authenticated = true;
                        this._userType = res.level;



                       // this.router.navigate(['/analytics']);
                        this.snackbar('Logged in as '+res.Name);
                    } else {
                        this.snackbar('You have been logged out');
                        this._authenticated = false;
                        this.router.navigate(['/home']);
                    }
                }
            );
            }
        } */
    }

    public getUser() {
        return this._user;
    }

    public setUser(user) {
        this._user = user;
    }


    snackbar(text) {
        this.ngzone.run(() => {
          const snackbar = this._snackBar.open(text, 'Dismiss', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
            });
         });
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter and getter for access token
     */
    set accessToken(token)
    {

    }

    get accessToken()
    {
        return '';
    }

    getToken() {
        try {
            var encodedToken = atob(localStorage.getItem('access_token'));
            return JSON.parse(encodedToken);
        } catch {
            return null;
        }
    }

    setToken(token) {
        var encodedToken = JSON.stringify(token);
        localStorage.setItem('access_token', btoa(encodedToken));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }


    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
     //   localStorage.removeItem('access_token');

        // Set the authenticated flag to false
        this._authenticated = false;
        this._userType = -1;
        localStorage.removeItem('access_token');


        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
