<h1 mat-dialog-title>Filter Routes</h1>
<p mat-dialog-subtitle>Specify the search criteria below</p>
<div mat-dialog-content class="filterContainer">

    <mat-tab-group mat-stretch-tabs #tabGroup>
        <mat-tab label="Matrix Search">
            <div class="flex flex-col gt-xs:flex-row"
            style="margin-top: 15px;">

            <mat-form-field class="flex-auto gt-xs:pr-3">
                <mat-select [placeholder]="'Packaging'" #packagingElm multiple>
                    <mat-option *ngFor="let elm of packagingArr; let i = index;" [value]="elm.id">{{elm.name}}</mat-option>
                </mat-select>
                <mat-icon matPrefix
                          [svgIcon]="'airport_shuttle'"></mat-icon>
            </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-select [placeholder]="'Commodities'" #productElm multiple>
                        <mat-option *ngFor="let elm of productsArr; let i = index;" [value]="elm.id">{{elm.name}}</mat-option>
                    </mat-select>
                    <mat-icon matPrefix
                              [svgIcon]="'category'"></mat-icon>
                </mat-form-field>
            </div>

            <div class="flex flex-col gt-xs:flex-row">
                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-select [placeholder]="'Routes longer than'" #distanceElm>
                        <mat-option [value]="1">Any</mat-option>
                        <mat-option [value]="250">250 km</mat-option>
                        <mat-option [value]="500">500 km</mat-option>
                        <mat-option [value]="1000">1000 km</mat-option>
                        <mat-option [value]="1500">1500 km</mat-option>
                    </mat-select>
                    <mat-icon matPrefix
                              [svgIcon]="'category'"></mat-icon>
                </mat-form-field>
                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-select [placeholder]="'Account'" #company multiple>
                        <mat-option *ngFor="let elm of clientsArr; let i = index;" [value]="elm.id">{{elm.name}}</mat-option>
                    </mat-select>
                    <mat-icon matPrefix
                              [svgIcon]="'business'"></mat-icon>
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Radius Search">
            <div style="margin-top: 15px;">
                <div class="flex flex-col gt-xs:flex-row"
                style="margin-top: 15px;">
                    <mat-form-field class="flex-auto gt-xs:pr-3" style="padding-right: 0 !important;">
                        <mat-select [placeholder]="'Routes longer than'" #distanceRadiusElm>
                            <mat-option [value]="250">250 km</mat-option>
                            <mat-option [value]="500">500 km</mat-option>
                            <mat-option [value]="1000">1000 km</mat-option>
                            <mat-option [value]="1500">1500 km</mat-option>
                        </mat-select>
                        <mat-icon matPrefix
                                [svgIcon]="'category'"></mat-icon>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3">
                        <mat-select [placeholder]="'Packaging'" #truckRadiusElm multiple>
                            <mat-option *ngFor="let elm of packagingArr; let i = index;" [value]="elm.name">{{elm.name}}</mat-option>
                        </mat-select>
                        <mat-icon matPrefix
                                  [svgIcon]="'airport_shuttle'"></mat-icon>
                    </mat-form-field>
                </div>

            </div>
        </mat-tab>
      </mat-tab-group>

</div>
<div mat-dialog-actions style="padding-bottom: 25px !important;">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-flat-button
    (click)="onSubmitSearch()"  style="background: #fbb03b !important;"
    color="primary">Search</button>
</div>
