import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Data, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from 'src/@treo/services/media-watcher/media-watcher.service';
import { TreoNavigationService } from 'src/@treo/components/navigation/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchMatrixPopupComponent } from '../../../../modules/admin/map/search-matrix-popup/search-matrix-popup.component';
import { OverlapsPopupComponent } from 'src/app/modules/admin/map/overlaps-popup/overlaps-popup.component';
import { ActionsService } from 'src/app/services/map/actions.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Route } from '@angular/compiler/src/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db/db.service';
import { DataService } from 'src/app/services/data/data.service';
@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    styleUrls    : ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    data: any;
    isScreenSmall: boolean;
    consumerPopopToggled: boolean = false;
    quarryPopopToggled: boolean = false;
    isOnMap: boolean = false;

    navigations;

    consumerFilterArray = [];
    quarryFilterArray = [];

    userName = '';
    userEmail = '';

    quarries = [];
    consumers = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     * @param {Router} _router
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        public searchDialog: MatDialog,
        public overlapDialog: MatDialog,
        private actionService: ActionsService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private ngZone: NgZone,
        private authService: AuthService,
        private dbService: DbService,
        private cdRef: ChangeDetectorRef,
        private dataService: DataService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.userName = localStorage.getItem('user');
        this.userEmail = localStorage.getItem('email');



        // Subscribe to the resolved route data
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
            this.navigations = this.data.navigation.default[0].items;

            let userType = 0;
            let isAuth = false;
          /*   if(isAuth == false) {
                var token = localStorage.getItem('access_token');
                if(token != null) {
                    var fetchedToken = this.getToken();
                    if(fetchedToken != null) {
                        this.dbService.loginUser(fetchedToken).subscribe( (res: any) => {
                            if(res != false) {
                                localStorage.setItem('user', res.Name);
                                localStorage.setItem('email', res.Email);

                                this.authService._authenticated = true;
                                userType = res.level;
                                this.authService._userType = userType;

                               // this.router.navigate(['/analytics']);
                                this.snackbar('Logged in as '+res.Name);

                                //Set navigation items for user type
                                this.ngZone.run(() => {
                                    var navData: any[] = this.data.navigation.default;
                                    this.navigations = navData.filter(elm => elm.level === userType)[0].items;
                                    this.cdRef.detectChanges();
                                });


                            } else {
                                this.snackbar('You have been logged out');
                                this.authService._authenticated = false;
                                this.router.navigate(['/home']);
                            }
                        }
                    );
                    } else {
                        this.snackbar('You have been logged out');
                        this.authService._authenticated = false;
                        this.router.navigate(['/home']);
                    }
                }    else {
                    this.snackbar('You have been logged out');
                    this.authService._authenticated = false;
                    this.router.navigate(['/home']);
                }

            } else {
                this.snackbar('You have been logged out');
                this.authService._authenticated = false;
                this.router.navigate(['/home']);
            } */


        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        if(this.router.url == '/map') this.isOnMap = true;
        this.router.events.subscribe( e => {
            if (e instanceof NavigationEnd) {
                if(e.url == '/map') this.isOnMap = true; else this.isOnMap = false;
            }
        });


  /*      var consumerInterval = setInterval(
            () => {
                this.populateConsumers();
                if(this.consumers.length > 0) clearInterval(consumerInterval);
            }, 3000
       );

       var quarryInterval = setInterval(
        () => {
            this.populateQuarries();
            if(this.quarries.length > 0) clearInterval(quarryInterval);
        }, 3000
   ); */

        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the breakpoint is 'lt-md'
                this.isScreenSmall = matchingAliases.includes('lt-md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    populateQuarries() {
        this.dataService.getQuarries.subscribe(
            (cons: any[]) => {
                this.dataService.getQuarryTypes.subscribe(
                    (conTypes: any[]) => {
                        this.quarries = conTypes.map(
                            (c: any) => {
                                var elm = c;
                                Object.assign(elm, {
                                    count: cons.filter( x => x.QuarryTypeID == c.QuarryTypeID).length
                                });
                                return elm;
                            }
                        )
                    }
                )
            }
        );
    }

    populateConsumers() {
        this.dataService.getConsumers.subscribe(
            (cons: any[]) => {
                this.dataService.getConsumerTypes.subscribe(
                    (conTypes: any[]) => {
                        this.consumers = conTypes.map(
                            (c: any) => {
                                var elm = c;
                                Object.assign(elm, {
                                    count: cons.filter( x => x.ConsumerTypeID == c.Id).length
                                });
                                return elm;
                            }
                        )
                    }
                )
            }
        );
    }

    getToken() {
        try {
            var encodedToken = atob(localStorage.getItem('access_token'));
            return JSON.parse(encodedToken);
        } catch {
            return null;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void
    {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(key);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    openSearchDialog() {
        this.searchDialog.open(SearchMatrixPopupComponent).afterClosed()
        .subscribe((data) => {
            if(data) this.actionService.emitSearchSubmittedChangeEvent(data);
        });
    }

    openOverlapDialog() {
        this.overlapDialog.open(OverlapsPopupComponent).afterClosed()
        .subscribe((data) => {
            if(data === 1) {
                this.actionService.emitOverlapSubmittedChangeEvent(data);
            }
        });
    }

    snackbar(text) {
        this.ngZone.run(() => {
          const snackbar = this._snackBar.open(text, 'Dismiss', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
            });
         });
      }

    openConsumerOverlay() {
        this.quarryPopopToggled = false;
        this.consumerPopopToggled = !this.consumerPopopToggled;
    }

    openQuarryOverlay() {
        this.consumerPopopToggled = false;
        this.quarryPopopToggled = !this.quarryPopopToggled;
    }

    selectConsumer(data) {
        var index = this.consumerFilterArray.indexOf(data);
        if(index > -1) {
            this.consumerFilterArray.splice(index, 1);
        } else if(index == -1) {
            if(data.count > 0) this.consumerFilterArray.push(data);
        }
    }

    selectQuarry(data) {
        var index = this.quarryFilterArray.indexOf(data);
        if(index > -1) {
            this.quarryFilterArray.splice(index, 1);
        } else if(index == -1) {
            if(data.count > 0) this.quarryFilterArray.push(data);
        }
    }

    openManualOverlap() {
        this.dbService.manualoverlap().subscribe( (res: any) => {
            console.log('openManualOverlap', res);
        });
        this.snackbar('Starting manual overlap.')
    }

    overlayQuarries() {
        this.actionService.emitQuarriesSubmittedChangeEvent(this.quarryFilterArray);
    }

    overlayConsumers() {
        this.actionService.emitConsumersSubmittedChangeEvent(this.consumerFilterArray);
    }

    clearConsumers() {
        this.actionService.emitConsumersClearedSubmittedChangeEvent();
        this.consumerPopopToggled = false;
    }

    clearQuarries() {
        this.actionService.emitQuarriesClearedSubmittedChangeEvent();
        this.quarryPopopToggled = false;
    }

    toggleRuler() {
        this.actionService.emitRulerSubmittedChangeEvent();
    }

    togglePadding() {
        this.actionService.emitPaddingSubmittedChangeEvent();
    }

    clearLayers() {
        this.actionService.emitClear();
        //
    }
}
