import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CapturedActionService {

  newCommunication: EventEmitter<any> = new EventEmitter();
  newRates: EventEmitter<any> = new EventEmitter();
  newDelivery: EventEmitter<any> = new EventEmitter();
  newProposals: EventEmitter<any> = new EventEmitter();
  newAcceptedProposals: EventEmitter<any> = new EventEmitter();
  constructor() {}

  //Communication Events
  addNewCommunication(comm : any) {
    Object.assign(comm, {RID: 1});
    this.newCommunication.emit(comm);
  }

  watchCommunications() {
    return this.newCommunication;
  }

  //Rates Events
  addNewRate(comm : any) {
    Object.assign(comm, {RID: 1});
    this.newRates.emit(comm);
  }

  watchRates() {
    return this.newRates;
  }

   //Proposals Events
   addNewProposal(comm : any) {
    Object.assign(comm, {RID: 1});
    this.newProposals.emit(comm);
   }

   watchProposal() {
    return this.newProposals;
   }

     //Accepted Proposals Events
     addNewAcceptedProposal(comm: any) {
      Object.assign(comm, {RID: 1});
      this.newAcceptedProposals.emit(comm);
     }

     watchAcceptedProposal() {
      return this.newAcceptedProposals;
     }

      //Delivery Events
      addDelivery(comm: any) {
        Object.assign(comm, {RID: 1});
        this.newDelivery.emit(comm);
       }

       watchDelivery() {
        return this.newDelivery;
       }
}
