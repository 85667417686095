import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {

  clearSubmitted: EventEmitter<any> = new EventEmitter();
  searchSubmitted: EventEmitter<any> = new EventEmitter();
  overlapSubmitted: EventEmitter<any> = new EventEmitter();
  rulerSubmitted: EventEmitter<any> = new EventEmitter();
  paddingSubmitted: EventEmitter<any> = new EventEmitter();

  quarriesSubmitted: EventEmitter<any> = new EventEmitter();
  consumerSubmitted: EventEmitter<any> = new EventEmitter();
  consumerClearedSubmitted: EventEmitter<any> = new EventEmitter();
  quarriesClearedSubmitted: EventEmitter<any> = new EventEmitter();
  overlapCalcSubmitted: EventEmitter<any> = new EventEmitter();
  constructor() {}


  //Search Events
  emitSearchSubmittedChangeEvent(search:any) {
    this.searchSubmitted.emit(search);
  }

  getSearchSubmittedEmitter() {
    return this.searchSubmitted;
  }

  //OverlapCal Events
  overlapCalcSubmittedChangeEvent(data:any) {
    this.overlapCalcSubmitted.emit(data);
  }

  overlapCalcSubmittedEmitter() {
    return this.overlapCalcSubmitted;
  }

  //Overlap Events
  emitOverlapSubmittedChangeEvent(search:any) {
    this.overlapSubmitted.emit(search);
  }

  getOverlapSubmittedEmitter() {
    return this.overlapSubmitted;
  }

   //Ruler Events
   emitRulerSubmittedChangeEvent() {
    this.rulerSubmitted.emit();
  }

  getRulerSubmittedEmitter() {
    return this.rulerSubmitted;
  }

    //Ruler Events
    emitPaddingSubmittedChangeEvent() {
      this.paddingSubmitted.emit();
    }

    getPaddingSubmittedEmitter() {
      return this.paddingSubmitted;
    }

  //Quarries Events
  emitQuarriesSubmittedChangeEvent(search:any) {
    this.quarriesSubmitted.emit(search);
  }

  getQuarriesSubmittedEmitter() {
    return this.quarriesSubmitted;
  }

  emitQuarriesClearedSubmittedChangeEvent() {
    this.quarriesClearedSubmitted.emit();
  }

  getQuarriesClearedSubmittedEmitter() {
    return this.quarriesClearedSubmitted;
  }

   //Consumers Events
   emitConsumersSubmittedChangeEvent(search :any) {
    this.consumerSubmitted.emit(search);
  }

  getConsumersSubmittedEmitter() {
    return this.consumerSubmitted;
  }

  emitConsumersClearedSubmittedChangeEvent() {
    this.consumerClearedSubmitted.emit();
  }

  getConsumersClearedSubmittedEmitter() {
    return this.consumerClearedSubmitted;
  }

  //Clear Map
  getClearSubmitEmitter() {
    return this.clearSubmitted;
  }

  emitClear() {
    this.clearSubmitted.emit();
  }

}
