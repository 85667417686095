import { AuthMockApi } from './auth';
import { CalendarMockApi } from './apps/calendar';
import { ContactsMockApi } from './apps/contacts';
import { AnalyticsMockApi } from './dashboards/analytics';
import { CryptoMockApi } from './dashboards/crypto';
import { ECommerceInventoryMockApi } from './apps/ecommerce/inventory';
import { FinanceMockApi } from './dashboards/finance';
import { HelpCenterMockApi } from './pages/help-center';
import { IconsMockApi } from './ui/icons';
import { MailboxMockApi } from './apps/mailbox';
import { MessagesMockApi } from './common/messages';
import { NavigationMockApi } from './common/navigation';
import { NotificationsMockApi } from './common/notifications';
import { SearchMockApi } from './common/search';
import { ShortcutsMockApi } from './common/shortcuts';
import { TasksMockApi } from './apps/tasks';
import { UserMockApi } from './common/user';

export const mockDataServices = [
    AnalyticsMockApi,
    AuthMockApi,
    CalendarMockApi,
    ContactsMockApi,
    CryptoMockApi,
    ECommerceInventoryMockApi,
    FinanceMockApi,
    HelpCenterMockApi,
    IconsMockApi,
    MailboxMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    SearchMockApi,
    ShortcutsMockApi,
    TasksMockApi,
    UserMockApi
];
