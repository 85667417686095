import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';

@Pipe({
  name: 'designation'
})
export class DesignationPipe implements PipeTransform {

  constructor(
    private dataService: DataService
  ) {}

  transform(value: number): any {

    var name = 'NA';
    var getPromise = new Promise((res, rej) => {

    });

    getPromise.then(
      (name) => {
        return name;
      }
    );
  }

}
