/// <reference types="@types/googlemaps" />
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { lineIntersect, lineString } from '@turf/turf';
import { DataService } from 'src/app/services/data/data.service';
import { DbService } from 'src/app/services/db/db.service';
import { ActionsService } from 'src/app/services/map/actions.service';
import * as L from 'leaflet';
import "leaflet-draw";
import "node_modules/leaflet-draw/dist/leaflet.draw.css";
// import "node_modules/leaflet/dist/images/marker-icon-2x.png";
// import "node_modules/leaflet/dist/images/marker-shadow.png";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*', display: 'block' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('Fading', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('200ms ease-in')),
    ])
  ],
})

export class MapComponent implements OnInit, OnDestroy {
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 25, 50, 100];

  routesDistance : any;
  hightlightOverlapRef: any;
  hightlightOverlapRefMarkers = [];
  routeAlternativesHighlights = [];
  radiusCoords = null;
  canShowAlternatives = false;
  showAlternativesRouteId = "";

  mapWidth = '50%';
  overlapWidth = '50%';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  rulerStartPin: any;
  rulerEndPin: any;
  rulerConnectorLine: any;
  rulerPolyline: any;
  rulerIsToggled = false;

  drawResultRoutes = [];

  zoomLock = false;
  totalOverlapDistance = 0;
  totalOverlaps = 0;

  canTruckColors = false;
  canProductColors = false;

  canShowOverlapsTable = false;
  canShowOverlaps = false;
  canShowDrawnRoutes = false;
  isCalculatingRoutes = false;
  canOverlapCalc = false;
  loadingOverlaps = false;
  drawnRectangle: any;

  individualHighlight = null;
  highlightedRoute = null;
  highlightedPair = [];

  trucks: any;
  truckscategories = [];
  products = [];
  productscategory = [];
  packaging = [];
  clients = [];
  routes: any;
  polylines: any;
  polylines_actual = [];

  private map;
  private directionsService: google.maps.DirectionsService;
  private geocodingService: google.maps.Geocoder;
  private polyLines = [];

  overlap: any;
  searchParams: any;

  finalOverlapsRefArray = [];
  overlapsGreyArray = [];

  polylineRefArray = [];
  polylineRefIDArray = [];
  requestRoutes = [];
  consumerRefArray = [];
  quarryRefArray = [];

  clearSubscription: any;
  paddingSubscription: any;
  rulerSubscription: any;
  searchSubscription: any;
  overlapSubscription: any;
  quarrySubscription: any;
  quarryClearedSubscription: any;
  consumerSubscription: any;
  consumerClearedSubscription: any;

  workerThread: Worker;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns: string[] = ['ID', 'Route One', 'Distance', 'Savings', 'Actions'];
  dataSource: MatTableDataSource<any>;
  selectedRow;

  constructor(private actionService: ActionsService,
    private _snackBar: MatSnackBar,
    private dbService: DbService,
    public dialog: MatDialog,
    public httpClient: HttpClient,
    private dataService: DataService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef) {

    if (typeof Worker !== 'undefined') {
      // Create a new
      this.workerThread = new Worker(new URL('../../../../app.worker', import.meta.url), {
        type: 'module'
      });
    }

    this.dataService.getaccounts.subscribe(elms => {
      this.clients = elms;
      if (this.clients) this.clients.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    });

    this.dataService.getpolylines.subscribe(
      (elms: any) => {
        this.polylines_actual = elms;
      }
    );

    this.dataService.gettrucktrailercategories.subscribe(elms => {
      this.truckscategories = elms;
    });

    this.dataService.getpackaging.subscribe(elms => {
      this.packaging = elms;
    });

    this.dataService.gettypetrucktrailer.subscribe(elms => {
      this.trucks = elms;
    });

    this.httpClient.get('https://nsas-mplukse7cq-uc.a.run.app/getroutes')
      .subscribe(
        (data) => {
          this.routes = data;
        }
      )

    this.dataService.getcommodities.subscribe((elms:any) => {
      this.products = elms;
    });

    this.dataService.getcommoditiescategory.subscribe((elms:any) => {
      this.productscategory = elms;
    });


  }

  toggleMapSize() {
    if (this.mapWidth === '50%') {
      this.mapWidth = '100%';
      this.overlapWidth = '0';
    } else {
      this.mapWidth = '50%';
      this.overlapWidth = '50%';
    }

    setTimeout(() => { this.map.invalidateSize() }, 100);
  }


  ngOnDestroy() { }

  snackbar(text) {
    this.ngZone.run(() => {
      const snackbar = this._snackBar.open(text, 'Dismiss', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    });
  }

  ngOnInit() {
    //subscribe to map actions
    this.searchSubscription = this.actionService.getSearchSubmittedEmitter()
      .pipe(debounceTime(1000))
      .subscribe(params => {

        if (params.searchType === 'matrix') {
          this.loadRoutes(params);
        } else if (params.searchType === 'radius') {
          if (this.radiusCoords == null) this.loadRadiusSearch(params)
        }

        this.searchParams = params;
      });

    this.clearSubscription = this.actionService.getClearSubmitEmitter()
      .subscribe(() => {

        this.map.eachLayer(function (layer :any) {
          this.map.removeLayer(layer);
        }.bind(this));

        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        tiles.addTo(this.map);

        this.snackbar('Success - Map Cleared');

        this.polylineRefArray = [];

        this.requestRoutes = [];

        if (this.highlightedRoute) this.highlightedRoute = null;
        this.closeOverlapTable();
      });

    this.overlapSubscription = this.actionService.getOverlapSubmittedEmitter()
      .subscribe(params => {
        console.log(params + "new params")
          this.overlaps(this.currentPage , this.pageSize);
      });

    this.consumerSubscription = this.actionService.getConsumersSubmittedEmitter()
      .subscribe(params => this.loadConsumers(params));

    this.quarrySubscription = this.actionService.getQuarriesSubmittedEmitter()
      .subscribe(params => this.loadQuarries(params));

    this.quarryClearedSubscription = this.actionService.getQuarriesClearedSubmittedEmitter()
      .subscribe(params => this.clearQuarries());

    this.consumerClearedSubscription = this.actionService.getConsumersClearedSubmittedEmitter()
      .subscribe(params => this.clearConsumers());

    this.rulerSubscription = this.actionService.getRulerSubmittedEmitter()
      .subscribe(params => this.toggleRuler());

    this.map = L.map('map', {
      center: [-29.535826, 24.273096],
      zoom: 5,
      preferCanvas: false,
    });

    const drawnItems = new L.FeatureGroup();
    this.map.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      position: 'topleft',
      draw: {
        polyline: false,
        circle: false,
        polygon: false,
        marker: false
      },
      edit: {
        featureGroup: drawnItems,
        edit: false
      }
    });

    this.map.addControl(drawControl);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    setTimeout(() => { this.map.invalidateSize() }, 1000);

    this.map.on('draw:created', (e) => {
      this.snackbar('Searching for routes...');

      if (this.drawnRectangle) this.map.removeLayer(this.drawnRectangle);

      this.drawnRectangle = e.layer;
      var layer = e.layer;
      var bounds = e.layer['_latlngs']
      this.map.addLayer(layer);

      this.polyLines.forEach(polyline => {
        var poolylinePath = lineString(polyline.path);
        var intersectTop = lineIntersect(poolylinePath, lineString([[bounds[0].lat, bounds[0].lng], [bounds[1].lat, bounds[1].lng]]));
        var intersectRight = lineIntersect(poolylinePath, lineString([[bounds[1].lat, bounds[1].lng], [bounds[2].lat, bounds[2].lng]]));
        var intersectBottom = lineIntersect(poolylinePath, lineString([[bounds[2].lat, bounds[2].lng], [bounds[3].lat, bounds[3].lng]]));
        var intersectleft = lineIntersect(poolylinePath, lineString([[bounds[3].lat, bounds[3].lng], [bounds[0].lat, bounds[0].lng]]));

        if (intersectTop.features.length > 0 || intersectRight.features.length > 0
          || intersectBottom.features.length > 0 || intersectleft.features.length > 0) {
          var route = this.routes.filter(elm => elm.RouteId === polyline.id)[0];
          var totalDistance = 0;

          var encodedPolyline = this.fetchRoutePolyline(route.RouteId);
          if (encodedPolyline != 'NA') {

            const decodedPoints = google.maps.geometry.encoding.decodePath(encodedPolyline);

            totalDistance = google.maps.geometry.spherical.computeLength(decodedPoints)
          }

          var pickup1 = this.clients.filter(elm => elm.Id === route.PickupClient)[0];
          var p1;
          if (pickup1 == null) p1 = 'NA'; else {
            if (pickup1.Company != null) {
              p1 = pickup1.Company;
            } else {
              p1 = pickup1.Name;
            }
          }
          if (p1 == null) p1 = 'NA';
          var dropoff1 = this.clients.filter(elm => elm.Id === route.DropoffClient)[0];
          var d1;
          if (dropoff1 == null) d1 = 'NA'; else {
            if (dropoff1.Company != null) {
              d1 = dropoff1.Company;
            } else {
              d1 = dropoff1.Name;
            }
          }
          if (d1 == null) d1 = 'NA';

          var product1 = this.products.filter(elm => elm.ProductId === route.Product)[0];
          var pr1;
          if (product1 == null) pr1 = 'NA'; else pr1 = product1.Description;
          if (pr1 == null) pr1 = 'NA';

          var truckType = this.trucks.filter(elm => elm.T_T_ID === route.TruckType)[0];
          var t;
          if (truckType == null) t = 'NA'; else t = truckType.T_T_Name;
          if (t == null) t = 'NA';

          this.drawResultRoutes.push({
            id: route.RouteId,
            pickupC: p1,
            dropoffC: d1,
            product: pr1,
            truck: t,
            distance: (totalDistance / 1000).toFixed(2),
            isHighlighted: false
          });
        }
      });
      if (this.drawResultRoutes.length > 0) {
        this.canShowDrawnRoutes = true;
      } else {
        this.canShowDrawnRoutes = false;
        this.snackbar('Error - No routes found');
        this.map.removeLayer(layer);
      }
    });

    this.map.on('dblclick', (e) => {
      if (this.highlightedRoute != null) {
        this.map.removeLayer(this.highlightedRoute);
      }
      if (this.highlightedPair.length > 0) {
        this.map.removeLayer(this.highlightedPair[0]);
        this.map.removeLayer(this.highlightedPair[1]);
        this.highlightedPair = [];
      }
    });
  }

  ngAfterViewInit() {
    this.initialize();
  }

  isGuid(value): boolean {
    var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    var match = regex.exec(value);
    return match != null;
  }

  initialize() {
    this.directionsService = new google.maps.DirectionsService();
  }

  onDrawnRouteClick(route) {
    this.polylineRefArray.forEach(elm => {
      elm.object.setStyle({
        color: 'grey'
      });
    });

    if (this.highlightedRoute) this.map.removeLayer(this.highlightedRoute);
    this.drawResultRoutes.forEach(elm => elm.isHighlighted = false);
    var index = this.drawResultRoutes.indexOf(route);
    this.drawResultRoutes[index].isHighlighted = true;

    var polyline = this.polylines.filter(elm => elm.RouteId === route.id)[0];
    const path = L.polyline(JSON.parse(polyline.Polyline), { className: 'animated_polyline' });
    this.map.addLayer(path);
    this.map.fitBounds(path.getBounds());
    this.highlightedRoute = path;
  }

  removePolylineLayer() {
    if (this.polylineRefArray.length > 0) {
      this.polylineRefArray.forEach(ref => {
        this.map.removeLayer(ref.object);
        this.map.removeLayer(ref.markers[0]);
        this.map.removeLayer(ref.markers[1]);
      });
    }
    if (this.highlightedRoute) {
      this.map.removeLayer(this.highlightedRoute);
      this.highlightedRoute = null;
    }
  }

  loadRadiusSearch(params: any) {
    if (this.radiusCoords == null) {
      this.radiusCoords = [];
      this.polylineRefArray.forEach(elm => {
        this.map.removeLayer(elm.object);
        this.map.removeLayer(elm.markers[0]);
        this.map.removeLayer(elm.markers[1]);
      });
      this.polylineRefArray = [];
      this.polylineRefIDArray = [];
      this.requestRoutes = [];
      this.routesDistance = 0;
      this.map.eachLayer(function (layer) {
        this.map.removeLayer(layer);
      }.bind(this));
      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      tiles.addTo(this.map);
      this.geocodingService = new google.maps.Geocoder();
      this.geocodingService.geocode({ address: params.location },
        (results) => {
          this.removePolylineLayer();
          var filteredRoutes = this.routes;
          if (params.truck) filteredRoutes = filteredRoutes.filter((elm: any) => { return params.truck.includes(elm.TruckType) });

          this.radiusCoords = L.latLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
          L.latLng(results[0].geometry.location.lat(), results[0].geometry.location.lng())
          const coords = this.radiusCoords;
          var radius = parseInt(params.radius);
          var circle = L.circle(coords, radius * 1000).addTo(this.map);

          var icon_offsetted_start = L.icon({
            iconUrl: '../../../../../assets/icons/start-pin-s.png',
            iconSize: [20, 25], // size of the icon
            shadowSize: [50, 64] // size of the shadow
          });

          L.marker(coords, { icon: icon_offsetted_start, opacity: 0.8 }).addTo(this.map);
          this.map.fitBounds(circle.getBounds());

          var circleCenter = new google.maps.LatLng(
            results[0].geometry.location.lat(), results[0].geometry.location.lng());
          var distance = radius * 1000;

          var topLeft = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(circleCenter, distance, 0), distance, 270);
          var topRight = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(circleCenter, distance, 0), distance, 90);
          var bottomLeft = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(circleCenter, distance, 180), distance, 270);
          var bottomRight = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(circleCenter, distance, 180), distance, 90);

          var rectangle = L.rectangle(
            [[topLeft.lat(), topLeft.lng()], [bottomRight.lat(), bottomRight.lng()]]
            , { color: "#ff7800", weight: 1 });

          var overlapsElms = [];

          this.polylines.forEach((polyline, i) => {
            var parsedPolyline = JSON.parse(polyline.Polyline);
            if (parsedPolyline.length > 2) {
              var poolylinePath = lineString(parsedPolyline);

              var intersectTop = lineIntersect(poolylinePath, lineString([
                [topLeft.lat(), topLeft.lng()], [topRight.lat(), topRight.lng()]
              ]));
              var intersectRight = lineIntersect(poolylinePath, lineString([
                [bottomRight.lat(), bottomRight.lng()], [topRight.lat(), topRight.lng()]
              ]));
              var intersectBottom = lineIntersect(poolylinePath, lineString([
                [bottomLeft.lat(), bottomLeft.lng()], [bottomRight.lat(), bottomRight.lng()]
              ]));
              var intersectleft = lineIntersect(poolylinePath, lineString([
                [topLeft.lat(), topLeft.lng()], [bottomLeft.lat(), bottomLeft.lng()]
              ]));

              if (intersectTop.features.length > 0 || intersectRight.features.length > 0
                || intersectBottom.features.length > 0 || intersectleft.features.length > 0) {
                overlapsElms.push(polyline);

                this.canShowDrawnRoutes = true;
                var route = this.routes.filter(elm => elm.RouteId === polyline.RouteId)[0];
                var totalDistance = 0;

                var encodedPolyline = this.fetchRoutePolyline(route.RouteId);
                var parsedPolyline = JSON.parse(encodedPolyline);
                if (encodedPolyline != 'NA') {

                  const decodedPoints = google.maps.geometry.encoding.decodePath(encodedPolyline);

                  totalDistance = google.maps.geometry.spherical.computeLength(decodedPoints)
                }

                var icon_offsetted_start = L.icon({
                  iconUrl: '../../../../../assets/icons/start-pin-s.png',
                  iconSize: [20, 25], // size of the icon
                  shadowSize: [50, 64], // size of the shadow
                  iconAnchor: [Math.floor(Math.random() * Math.floor(30)), Math.floor(Math.random() * Math.floor(30))]
                });

                var icon_offsetted_end = L.icon({
                  iconUrl: '../../../../../assets/icons/end-pin-s.png',
                  iconSize: [20, 25], // size of the icon
                  shadowSize: [50, 64], // size of the shadow
                  iconAnchor: [Math.floor(Math.random() * Math.floor(30)), Math.floor(Math.random() * Math.floor(30))]
                });

                const startM = L.marker(parsedPolyline[0], { icon: icon_offsetted_start, opacity: 0.8, title: "(Start) ID: " + route.RouteId }).addTo(this.map);
                const endM = L.marker(parsedPolyline[parsedPolyline.length - 1], { icon: icon_offsetted_end, opacity: 0.8, title: "(End) ID: " + route.RouteId }).addTo(this.map);
                const polylineRef = L.polyline(parsedPolyline, { color: '#fbb03b' }).addTo(this.map);

                var pickup1 = this.clients.filter(elm => elm.Id === route.PickupClient)[0];
                var p1;
                if (pickup1 == null) p1 = 'NA'; else {
                  if (pickup1.Company != null) {
                    p1 = pickup1.Company;
                  } else {
                    p1 = pickup1.Name;
                  }
                }

                if (p1 == null) p1 = 'NA';
                var dropoff1 = this.clients.filter(elm => elm.Id === route.DropoffClient)[0];
                var d1;
                if (dropoff1 == null) d1 = 'NA'; else {
                  if (dropoff1.Company != null) {
                    d1 = dropoff1.Company;
                  } else {
                    d1 = dropoff1.Name;
                  }
                }
                if (d1 == null) d1 = 'NA';

                var product1 = this.products.filter(elm => elm.ProductId === route.Product)[0];
                var pr1;
                if (product1 == null) pr1 = 'NA'; else pr1 = product1.Description;
                if (pr1 == null) pr1 = 'NA';

                var truckType = this.trucks.filter(elm => elm.T_T_ID === route.TruckType)[0];
                var t;
                if (truckType == null) t = 'NA'; else t = truckType.T_T_Name;
                if (t == null) t = 'NA';

                this.drawResultRoutes.push({
                  id: route.RouteId,
                  pickupC: p1,
                  dropoffC: d1,
                  product: pr1,
                  truck: t,
                  distance: route.distance,
                  isHighlighted: false
                });
              }

              if ((this.polylines.length - 1) === i) {
              }
            }
          });

          var interval = setInterval(
            () => {
              this.radiusCoords = null;
              clearInterval(interval);
            }, 4000
          )
        }
      );
    }
  }

  async loadRoutes(params) {
    this.polylineRefArray.forEach(elm => {
      this.map.removeLayer(elm.object);
      this.map.removeLayer(elm.markers[0]);
      this.map.removeLayer(elm.markers[1]);
    })
    this.polylineRefArray = [];
    this.polylineRefIDArray = [];
    this.requestRoutes = [];
    this.routesDistance = 0;
    this.ngZone.run(() => {

      this.removePolylineLayer();

      var filteredRoutes = this.routes;

      if (params.Company) filteredRoutes = filteredRoutes.filter((elm: any) => {
        var ismatch = false;
        if (params.Company.includes(elm.loadingpoint)) ismatch = true;
        if (params.Company.includes(elm.deliverypoint)) ismatch = true;
        return ismatch;
      });
      if (params.Packaging) filteredRoutes = filteredRoutes.filter((elm: any) => { return params.Packaging.includes(elm.packaging) });
      if (params.Product) filteredRoutes = filteredRoutes.filter((elm: any) => { return params.Product.includes(elm.commodity) });
      // if(params.PickupClient) filteredRoutes = filteredRoutes.filter((elm: any) => {return params.PickupClient.includes(elm.PickupClient)} );
      //   if(params.DropoffClient) filteredRoutes = filteredRoutes.filter((elm: any) => {return params.DropoffClient.includes(elm.DropoffClient)});
      if (params.Distance) filteredRoutes = filteredRoutes.filter(
        (elm: any) => {
          const dis = Math.floor((elm.distance));
          return dis > params.Distance
        }
      );


      for (let route of filteredRoutes) {
        //check if last route
        var routeIndex = filteredRoutes.indexOf(route);
        if (routeIndex == (filteredRoutes.length - 1))
        this.snackbar(`${this.polylineRefIDArray.length} routes loaded`);

        if (route.polyline) {
          var encodedPolyline = google.maps.geometry.encoding.decodePath(route.polyline).map(
            (coord: any) => {
              return [coord.lat(), coord.lng()]
            }
          );
          if (encodedPolyline.length > 0) {
            const latlngs = encodedPolyline;
            const distance = route.distance;
            this.routesDistance += distance;

            var icon_offsetted_start = L.icon({
              iconUrl: '../../../../../assets/icons/start-pin-s.png',
              iconSize: [15, 19], // size of the icon
              shadowSize: [50, 64], // size of the shadow
              iconAnchor: [Math.floor(Math.random() * Math.floor(30)), Math.floor(Math.random() * Math.floor(30))]
            });

            var icon_offsetted_end = L.icon({
              iconUrl: '../../../../../assets/icons/end-pin-s.png',
              iconSize: [15, 19], // size of the icon
              shadowSize: [50, 64], // size of the shadow
              iconAnchor: [Math.floor(Math.random() * Math.floor(30)), Math.floor(Math.random() * Math.floor(30))]
            });

            const startM = L.marker([latlngs[0][0], latlngs[0][1]], { icon: icon_offsetted_start, opacity: 0.8, title: "(Start) ID: " + route.id }).addTo(this.map);
            const endM = L.marker([latlngs[latlngs.length - 1][0], latlngs[latlngs.length - 1][1]], { icon: icon_offsetted_end, opacity: 0.8, title: "(End) ID: " + route.id }).addTo(this.map);



            var routeType = 'Actual';
            // if(route.TypeActual === false) routeType = 'Potential';

            startM.on('click', (e) => {

              //Remove Pair Highlight
              if (this.highlightedRoute) {
                this.map.removeLayer(this.highlightedRoute);
                this.highlightedRoute = null;
              }

              //Remove Pair Highlight
              if (this.highlightedPair.length > 0) {
                this.map.removeLayer(this.highlightedPair[0]);
                this.map.removeLayer(this.highlightedPair[1]);
                this.highlightedPair = [];
              }
              this.routeAlternativesHighlights.forEach(
                (elm: any) => {
                  this.map.removeLayer(elm);
                }
              );

              if (this.highlightedRoute != null) {
                this.map.removeLayer(this.highlightedRoute);
              };

              const path = L.polyline(latlngs, { className: 'animated_polyline' });
              this.map.addLayer(path);
              this.highlightedRoute = path;

              path.on('click', (e) => {
                this.map.removeLayer(e.layer);
                this.map.removeLayer(e.target);
              });

              this.showAlternativesRouteId = route.id;
              this.canShowAlternatives = true;

              document.getElementById('routeDataWindow').innerHTML = '<div style="display: flex; padding: 15px; width: 100%;"> <div style="display: block;width: 50%;"><p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Route:</span>: ' + route.id + '</p><p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Name:</span>: ' + route.name + '</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Distance:</span> ' + route.distance + ' km</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Pickup Client:</span> ' + this.replaceAccountName(route.loadingpoint) + '</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Dropoff Client:</span> ' + this.replaceAccountName(route.deliverypoint) + '</p> </div>' +
                ' <div style="display: block" style="width: 50%;"> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Truck Type:</span> ' + this.replaceTruckName(route.trucktrailertype) + ' - ' + this.replaceTruckCategoryName(route.trucktrailercategory) + '</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Commodity:</span> ' + this.replaceCommodityName(route.commodity) + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Commodity Category:</span> ' + this.replaceCommodityCategoryName(route.commoditycategory) + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Packaging:</span> ' + this.replacePackagingName(route.packaging) + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Route Type:</span> ' + routeType + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Average Monthly Tonnage:</span> ' + route.Quantity + '</p></div></div>';
            });


            endM.on('click', (e) => {

              //Remove Pair Highlight
              if (this.highlightedRoute) {
                this.map.removeLayer(this.highlightedRoute);
                this.highlightedRoute = null;
              }

              this.routeAlternativesHighlights.forEach(
                (elm: any) => {
                  this.map.removeLayer(elm);
                }
              );

              //Remove Pair Highlight
              if (this.highlightedPair.length > 0) {
                this.map.removeLayer(this.highlightedPair[0]);
                this.map.removeLayer(this.highlightedPair[1]);
                this.highlightedPair = [];
              }

              if (this.highlightedRoute != null) {
                this.map.removeLayer(this.highlightedRoute);
              };

              const path = L.polyline(latlngs, { className: 'animated_polyline' });
              this.map.addLayer(path);
              this.highlightedRoute = path;

              path.on('click', (e) => {
                this.map.removeLayer(e.layer);
                this.map.removeLayer(e.target);
              });

              this.showAlternativesRouteId = route.id;
              this.canShowAlternatives = true;

              document.getElementById('routeDataWindow').innerHTML = '<div style="display: flex; padding: 15px; width: 100%;"> <div style="display: block;width: 50%;"><p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Route:</span>: ' + route.id + '</p><p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Name:</span>: ' + route.name + '</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Distance:</span> ' + route.distance + ' km</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Pickup Client:</span> ' + this.replaceAccountName(route.loadingpoint) + '</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Dropoff Client:</span> ' + this.replaceAccountName(route.deliverypoint) + '</p> </div>' +
                ' <div style="display: block" style="width: 50%;"> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Truck Type:</span> ' + this.replaceTruckName(route.trucktrailertype) + ' - ' + this.replaceTruckCategoryName(route.trucktrailercategory) + '</p>' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Commodity:</span> ' + this.replaceCommodityName(route.commodity) + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Commodity Category:</span> ' + this.replaceCommodityCategoryName(route.commoditycategory) + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Packaging:</span> ' + this.replacePackagingName(route.packaging) + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Route Type:</span> ' + routeType + '</p> ' +
                '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Average Monthly Tonnage:</span> ' + route.Quantity + '</p></div></div>';
            });



            var polylineColor = '#fbb03b';

            // if(pr1.includes('AFGRI')) polylineColor = 'green';
            const polyline = L.polyline(latlngs, { color: polylineColor, opacity: 0.6 }).addTo(this.map);

            this.polylineRefIDArray.push(route.id);
            this.polylineRefArray.push({ id: route.id, object: polyline, markers: [startM, endM], truck: route.trucktrailercategory, product: route.commodity });
            this.requestRoutes.push({ id: route.id, type: route.trucktrailercategory, path: latlngs })
            this.polyLines.push({ id: route.id, type: route.trucktrailercategory, path: latlngs });
          }
        }
      }
    });
  }

  displayAlternativeRoutes() {
    const routeid = this.showAlternativesRouteId;

    this.routeAlternativesHighlights.forEach(
      (elm: any) => {
        this.map.removeLayer(elm);
      }
    );

    this.routeAlternativesHighlights = [];
    this.polylines_actual.forEach(
      (element: any, polylineIndex) => {
        if (element.route == routeid && element.alt == 1) {

          const latlngs = google.maps.geometry.encoding.decodePath(element.polyline).map(
            (coord: any) => {
              return [coord.lat(), coord.lng()]
            }
          );

          const path = L.polyline(latlngs, { className: 'animated_polyline_red', color: 'red' });
          this.map.addLayer(path);

          path.bindPopup(`${element.distance} KM`);
          path.openPopup();
          this.routeAlternativesHighlights.push(path);

        } else {
          if (polylineIndex == (this.polylines_actual.length - 1)) {
            if (this.routeAlternativesHighlights.length == 0) {
              this.snackbar('No alternative routes found')
            }
          }
        }
      }
    );
  }

  replaceAccountName(id: string): any {
    var index = this.clients.map(
      (client: any) => { return client.id }
    ).indexOf(id);

    if (index > -1) return this.clients[index].name
  }

  replaceTruckCategoryName(id: string): any {
    var index = this.truckscategories.map(
      (client: any) => { return client.id }
    ).indexOf(id);

    if (index > -1) return this.truckscategories[index].name
  }

  replaceTruckName(id: string): any {
    var index = this.trucks.map(
      (client: any) => { return client.id }
    ).indexOf(id);

    if (index > -1) return this.trucks[index].name
  }

  replaceTruckTypeName(id: string): any {
    if (this.isGuid(id)) {
      var index = this.trucks.map(
        (truck: any) => { return truck.id }
      ).indexOf(id);

      if (index > -1) return this.trucks[index].name
    } else {
      return id
    }
  }

  replaceCommodityName(id: string): any {
    if (this.isGuid(id)) {
      var index = this.products.map(
        (products: any) => { return products.id }
      ).indexOf(id);

      if (index > -1) return this.products[index].name
    } else {
      return id
    }
  }

  replacePackagingName(id: string): any {
    if (this.isGuid(id)) {
      var index = this.packaging.map(
        (packaging: any) => { return packaging.id }
      ).indexOf(id);

      if (index > -1) return this.packaging[index].name
    } else {
      return id
    }
  }

  replaceCommodityCategoryName(id: string): any {
    var index = this.productscategory.map(
      (productscategory: any) => { return productscategory.id }
    ).indexOf(id);

    if (index > -1) return this.productscategory[index].name
  }


  onOverlapClick(route) {

    console.log('onOverlapClick', route)

    var route1 = route.route1Ref;
    var route2 = route.route2Ref;

    document.getElementById('routeDataWindow').innerHTML = '<div style="display: flex; padding: 15px; width: 100%;padding-bottom: 0;"> <div style="display: block;width: 50%;"><p class="overflow-p" style="margin: 5px 0;" id="routeType1"><span class="bold-text-bg">Route One:</span> ' + route1.id + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Route Name:</span> ' + route1.name + ' KM </p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Distance:</span> ' + route1.distance + ' KM </p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Commodity:</span> ' + this.replaceCommodityName(route1.commodity) + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Truck Type:</span> ' + this.replaceTruckTypeName(route.route1Ref.trucktrailertype) + '</p> ' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Packaging:</span> ' + this.replacePackagingName(route1.packaging) + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;" ><span class="bold-text-bg">Monthly Volume:</span> ' + route1.monthlyvolume + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Owner:</span> ' + this.replaceAccountName(route1.owner) + '</p> ' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Pickup Location:</span> ' + this.replaceAccountName(route1.loadingpoint) + '</p> ' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Dropoff Location:</span> ' + this.replaceAccountName(route1.deliverypoint) + '</p> </div>' +
      ' <div style="display: block;width: 50%" > ' +

      '<p class="overflow-p" style="margin: 5px 0;"  id="routeType2"><span class="bold-text-bg">Route Two:</span> ' + route2.id + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Route Name:</span> ' + route2.name + ' KM </p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Distance:</span> ' + route2.distance + ' KM </p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Commodity:</span> ' + this.replaceCommodityName(route2.commodity) + '</p> ' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Truck Type:</span> ' + this.replaceTruckTypeName(route.route1Ref.trucktrailertype) + '</p> ' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Packaging:</span> ' + this.replacePackagingName(route2.packaging) + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Monthly Volume:</span> ' + route2.monthlyvolume + '</p> ' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Owner:</span> ' + this.replaceAccountName(route2.owner) + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Pickup Location:</span> ' + this.replaceAccountName(route2.loadingpoint) + '</p>' +
      '<p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Dropoff Location:</span> ' + this.replaceAccountName(route2.deliverypoint) + '</p></div>' +
      '</div><div style="width: 100%; margin-left: 15px;display: flex;"><p class="overflow-p" style="margin: 5px 0;"><span class="bold-text-bg">Overlap Distance:</span> ' + route.distance + ' KM</p></div>';

  }


  sortBy(array: any[]) {
    return array;
  }

  findPolylineIntersections(data: any[]) {
    console.log("findPolylineIntersections")
    var routes = data.filter(
      (element: any) => element.route1Ref != undefined && element.route2Ref != undefined
    )
    .map(
      (route: any) => {
        route.route1Ref.trucktrailertype = this.replaceTruckTypeName(route.route1Ref.trucktrailertype);
        route.route2Ref.trucktrailertype = this.replaceTruckTypeName(route.route2Ref.trucktrailertype);

        return route;
      }
    );

    this.loadingOverlaps = false;
    this.dataSource = new MatTableDataSource(routes);
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.canShowOverlaps = true;
    this.canShowOverlapsTable = true;
    this.finalOverlapsRefArray = [];
    this.finalOverlapsRefArray = routes;

    routes.forEach((overlap: any) => {
      // Create polyline and add to map
      var polyline = L.polyline(overlap.points, { color: 'grey', weight: 8, opacity: 0 }).bindPopup(
        '<h5> Overlap of Routes' + overlap.route1 + ' and ' + overlap.route2 + '. Length is ' + overlap.distance + "km"
      ).addTo(this.map);
      overlap.polyline = polyline;
      this.overlapsGreyArray.push(overlap.polyline);

      if (overlap.polyline) {
        overlap.polyline.on('click', (e) => {
          //Remove highlight if visible
          if (this.highlightedRoute != null) {
            this.map.removeLayer(this.highlightedRoute);
            this.highlightedRoute = null;
          }

          if (this.highlightedPair.length > 0) {
            this.map.removeLayer(this.highlightedPair[0]);
            this.map.removeLayer(this.highlightedPair[1]);

            this.highlightedPair = [];
          };

          var points1 = this.polyLines.filter(e => e.id == overlap.route1);
          var points2 = this.polyLines.filter(e => e.id == overlap.route2);

          const path1 = L.polyline(points1[0].path, { className: 'animated_polyline' });
          this.map.addLayer(path1);

          const path2 = L.polyline(points2[0].path, { className: 'animated2_polyline' });
          this.map.addLayer(path2);
          this.highlightedPair.push(path1);
          this.highlightedPair.push(path2);
        });
      }
    })
  }

  removeoverlap(overlap, reason) {
    this.dbService
      .deleteOverlap({
        routeid: overlap.RouteOverlapID,
        reason: reason
      })
      .subscribe(
        () => {
          var temp = this.dataSource.data;
          var index = temp.indexOf(overlap);

          temp.splice(index, 1);
          this.dataSource.data = temp;
          this.changeDetectorRef.detectChanges();

          this.snackbar(`Removed Overlap #${overlap.RouteOverlapID} for ${reason}`)
        }
      );
  }

  highlightOverlap(overlap) {
    this.polylineRefArray.forEach(elm => {
      this.map.removeLayer(elm.object);
      this.map.removeLayer(elm.markers[0]);
      this.map.removeLayer(elm.markers[1]);
    });

    this.hightlightOverlapRefMarkers.forEach(
      el => {
        this.map.removeLayer(el);
      }
    );

    this.onOverlapClick(overlap);

    this.map.panTo(new L.LatLng(-29.089812, 25.225349));
    this.map.setZoom(5)

    //Set highlight of overlap item in opp. table
    this.finalOverlapsRefArray.forEach(overlap => overlap.isHighlighted = false);
    var index = this.finalOverlapsRefArray.indexOf(overlap);
    if (index > -1) this.finalOverlapsRefArray[index].isHighlighted = true;

    if (this.highlightedPair.length > 0) {
      this.map.removeLayer(this.highlightedPair[0]);
      this.map.removeLayer(this.highlightedPair[1]);
      this.map.removeLayer(this.highlightedPair[2]);
      this.highlightedPair = [];
    };

    var refPoints = this.finalOverlapsRefArray[index].polyline._latlngs;
    const polyline = L.polyline(refPoints, { color: 'red', weight: 10 });

    let points1 = google.maps.geometry.encoding.decodePath(overlap.route1Ref.polyline).map(
      (coord: any) => {
        return [coord.lat(), coord.lng()]
      }
    );

    var points2 = google.maps.geometry.encoding.decodePath(overlap.route2Ref.polyline).map(
      (coord: any) => {
        return [coord.lat(), coord.lng()]
      }
    );

    if (this.highlightedRoute != null) {
      this.map.removeLayer(this.highlightedRoute);
      this.highlightedRoute = null;
    }

    if (this.individualHighlight) {
      this.map.removeLayer(this.individualHighlight);
    }
    var path1 = L.polyline(points1, { className: 'animated_polyline' });
    var path2 = L.polyline(points2, { className: 'animated_polyline' });
    var overlap_polyline = L.polyline(overlap.points, { color: 'red', opacity: 1 });

    var icon_offsetted_start = L.icon({
      iconUrl: '../../../../../assets/icons/start-pin-s.png',
      iconSize: [20, 25], // size of the icon
      shadowSize: [50, 64], // size of the shadow
      iconAnchor: [Math.floor(Math.random() * Math.floor(30)), Math.floor(Math.random() * Math.floor(30))]
    });

    var icon_offsetted_end = L.icon({
      iconUrl: '../../../../../assets/icons/end-pin-s.png',
      iconSize: [20, 25], // size of the icon
      shadowSize: [50, 64], // size of the shadow
      iconAnchor: [Math.floor(Math.random() * Math.floor(30)), Math.floor(Math.random() * Math.floor(30))]
    });

    const startM1 = L.marker(points1[0], { icon: icon_offsetted_start, opacity: 0.8 }).addTo(this.map);
    const startM2 = L.marker(points2[0], { icon: icon_offsetted_start, opacity: 0.8 }).addTo(this.map);

    const endM1 = L.marker(points1[points1.length - 1], { icon: icon_offsetted_end, opacity: 0.8 }).addTo(this.map);
    const endM2 = L.marker(points2[points2.length - 1], { icon: icon_offsetted_end, opacity: 0.8 }).addTo(this.map);

    startM1.on('click', (e) => {
      path1.setStyle({
        color: 'yellow'
      });

      { className: 'animated_polyline' }

      path2.setStyle({
        color: 'blue'
      });
    });

    startM2.on('click', (e) => {
      path1.setStyle({
        color: 'blue'
      });

      path2.setStyle({
        color: 'yellow'
      });
    });

    endM1.on('click', (e) => {
      path1.setStyle({
        color: 'yellow'
      });

      path2.setStyle({
        color: 'blue'
      });
    });

    endM2.on('click', (e) => {
      path1.setStyle({
        color: 'blue'
      });

      path2.setStyle({
        color: 'yellow'
      });
    });

    this.hightlightOverlapRefMarkers.push(startM1);
    this.hightlightOverlapRefMarkers.push(startM2);
    this.hightlightOverlapRefMarkers.push(endM1);
    this.hightlightOverlapRefMarkers.push(endM2);

    this.map.addLayer(path1);
    this.map.addLayer(path2);
    this.map.addLayer(overlap_polyline);
    this.highlightedPair.push(path1);
    this.highlightedPair.push(path2);
    this.highlightedPair.push(overlap_polyline);

    if (this.individualHighlight != null) {
      this.map.removeLayer(this.individualHighlight);
    }
    this.individualHighlight = polyline;
  }

  getRandomColor(): string {
    var value = Math.random() * 0xFF | 0;
    var grayscale = (value << 16) | (value << 8) | value;
    var color = '#' + grayscale.toString(16);
    return color;
  }

  loadConsumers(data) {
    this.dataService.getConsumers
      .subscribe(
        (cons: any[]) => {
          this.consumerRefArray.forEach(refElm => this.map.removeLayer(refElm));
          const displayList = [];
          data.forEach(dataPoint => {
            var temp = cons.filter(elm => elm.ConsumerTypeID == dataPoint.Id);
            temp.forEach(tempElm => {
              displayList.push(tempElm);
            });
          });

          displayList.forEach(item => {
            const consumerIcon = L.icon({
              iconUrl: this.getConsumerIcon(item.ConsumerTypeID),
              shadowUrl: "marker-shadow.png",
              iconSize: [35, 35], // size of the icon
              shadowSize: [35, 35], // size of the shadow
            });

            const marker = L.marker([item.Latitude, item.Longitude], { opacity: 0.8, icon: consumerIcon }).bindPopup(
              'Company :' + item.CompanyName + '<br />' +
              'Contact Person :' + item.ContactPerson + '<br />' +
              'Contact Number :' + item.ContactNo + '<br />' +
              'Website : <strong>' + item.Website + '</strong> <br />' +
              'Email : <strong>' + item.EmailAddress + '</strong> <br />'
            );
            this.map.addLayer(marker);
            this.consumerRefArray.push(marker);
          });
        }
      )
  }

  getConsumerIcon(id) {
    var type = 'marker-icon-2x.png';
    switch (id) {
      case 1:
        type = "../../../../../assets/icons/Smelters.png"
        break;
      case 2:
        type = "../../../../../assets/icons/ReadyMix.png"
        break;
      case 3:
        type = "../../../../../assets/icons/BrickPlants.png"
        break;
      case 4:
        type = "../../../../../assets/icons/Foundries.png"
        break;
      case 5:
        type = "../../../../../assets/icons/Waterworks.png"
        break;
      case 6:
        type = "../../../../../assets/icons/TileAdhesives.png"
        break;
      case 7:
        type = "../../../../../assets/icons/NewRoads.png"
        break;
      case 8:
        type = "../../../../../assets/icons/ConstructionSites.png"
        break;
      case 9:
        type = "../../../../../assets/icons/GlassManufacturers.png"
        break;
      case 10:
        type = "../../../../../assets/icons/silos.png"
        break;
      case 11:
        type = "../../../../../assets/icons/rooftiles.png"
        break;
      case 12:
        type = "../../../../../assets/icons/hardware.png"
        break;
      case 13:
        type = "../../../../../assets/icons/powerstation.png"
        break;
    }
    return type;
  }

  loadQuarries(data) {
    this.dataService.getQuarries
      .subscribe(
        (quarrs: any[]) => {
          this.quarryRefArray.forEach(refElm => this.map.removeLayer(refElm));

          const displayList = [];
          data.forEach(dataPoint => {
            var temp = quarrs.filter(elm => elm.QuarryTypeID == dataPoint.QuarryTypeID);
            temp.forEach(tempElm => {
              displayList.push(tempElm);
            });
          });

          displayList.forEach(item => {
            const quarryIcon = L.icon({
              iconUrl: this.getQuarryIcon(item.QuarryTypeID),
              shadowUrl: "marker-shadow.png",
              iconSize: [35, 35], // size of the icon
              shadowSize: [35, 35], // size of the shadow
            });

            const marker = L.marker([item.Latitude, item.Longitude], { opacity: 0.8, icon: quarryIcon }).bindPopup(
              'Name :' + item.Name + '<br />' +
              'Contact Person :' + item.ContactPerson + '<br />' +
              'Contact Number :' + item.ContactNo + '<br />' +
              'Website : <strong>' + item.Website + '</strong> <br />' +
              'Email : <strong>' + item.Email + '</strong> <br />'
            ).addTo(this.map);
            this.quarryRefArray.push(marker);
          });
        }
      )
  }

  getQuarryIcon(id) {
    var type = 'marker-icon-2x.png';
    switch (id) {
      case 1:
        type = "../../../../../assets/icons/qA.png"
        break;
      case 2:
        type = "../../../../../assets/icons/qS.png"
        break;
      case 3:
        type = "../../../../../assets/icons/qL.png"
        break;
      case 4:
        type = "../../../../../assets/icons/qD.png"
        break;
      case 5:
        type = "../../../../../assets/icons/qSi.png"
        break;
    }
    return type;
  }

  clearConsumers() {
    this.consumerRefArray.forEach(refElm => this.map.removeLayer(refElm));
  }

  clearQuarries() {
    this.quarryRefArray.forEach(refElm => this.map.removeLayer(refElm));
  }

  closeOverlapTable() {
    this.polylineRefArray.forEach(elm => {
      elm.object.setStyle({
        color: '#fbb03b'
      });
      this.map.addLayer(elm.object);
      this.map.addLayer(elm.markers[0]);
      this.map.addLayer(elm.markers[1]);
    });

    this.dataSource = null;
    if (this.individualHighlight) this.map.removeLayer(this.individualHighlight);

    this.canShowOverlaps = false;
    this.canShowDrawnRoutes = false;

    if (this.highlightedPair.length > 1) {
      this.map.removeLayer(this.highlightedPair[0]);
      this.map.removeLayer(this.highlightedPair[1]);
    }

    if (this.overlapsGreyArray) {
      this.overlapsGreyArray.forEach(elm => this.map.removeLayer(elm));
      this.overlapsGreyArray = [];
    }

    this.drawResultRoutes = [];
    if (this.highlightedRoute) this.map.removeLayer(this.highlightedRoute);
    if (this.drawnRectangle) this.map.removeLayer(this.drawnRectangle);

    this.map.eachLayer(function (layer) {
      this.map.removeLayer(layer);
    }.bind(this));

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }

  toggleRuler() {
    if (this.rulerIsToggled == false) {
      this.rulerIsToggled = !this.rulerIsToggled;
      var rulerIcon = L.icon({
        iconUrl: "../../../../../assets/icons/marker-ruler.png",
        iconSize: [20, 30]
      });

      var start = L.latLng(this.map.getCenter());
      const markerStart = L.marker([start.lat, start.lng], { draggable: true, icon: rulerIcon }).addTo(this.map);
      const markerEnd = L.marker([(start.lat - 0.1), (start.lng - 0.1)], { draggable: true, icon: rulerIcon }).addTo(this.map);

      markerStart.on("drag", function (e) {
        var position = e.target.getLatLng();
        this.map.removeLayer(this.rulerConnectorLine);
        this.rulerConnectorLine = L.polyline([position, this.rulerEndPin.getLatLng()], { color: 'black' }).addTo(this.map);
      }.bind(this));

      markerEnd.on("drag", function (e) {
        var position = e.target.getLatLng();
        this.map.removeLayer(this.rulerConnectorLine);
        this.rulerConnectorLine = L.polyline([this.rulerStartPin.getLatLng(), position], { color: 'black' }).addTo(this.map);
      }.bind(this));

      markerStart.on("dragend", function (e) {
        var position = e.target.getLatLng();
        this.connectRulerPoints(position, this.rulerEndPin.getLatLng());
      }.bind(this));

      markerEnd.on("dragend", function (e) {
        var position = e.target.getLatLng();
        this.connectRulerPoints(this.rulerStartPin.getLatLng(), position);
      }.bind(this));

      this.rulerStartPin = markerStart;
      this.rulerEndPin = markerEnd;
      this.rulerConnectorLine = L.polyline([markerStart.getLatLng(), markerEnd.getLatLng()], { color: 'black' }).addTo(this.map);
    } else {
      this.rulerIsToggled = !this.rulerIsToggled;
      this.map.removeLayer(this.rulerPolyline);
      this.map.removeLayer(this.rulerStartPin);
      this.map.removeLayer(this.rulerEndPin);
      this.map.removeLayer(this.rulerConnectorLine);
      this.rulerPolyline, this.rulerConnectorLine, this.rulerStartPin, this.rulerEndPin = null;
    }
  }

  connectRulerPoints(start, end) {
    if (this.rulerPolyline) this.map.removeLayer(this.rulerPolyline);
    var request = {
      origin: new google.maps.LatLng(start.lat, start.lng),
      destination: new google.maps.LatLng(end.lat, end.lng),
      travelMode: google.maps.TravelMode.DRIVING,
      avoidFerries: false,
      avoidHighways: false,
      avoidTolls: false,
      unitSystem: google.maps.UnitSystem.METRIC
    };

    this.directionsService.route(request, (result, status) => {
      if (status.toString() === 'OK') {

        //Build polyline Path
        var points = [];
        var segments = result.routes[0].legs[0].steps;
        segments.forEach((seg) => {
          seg.path.forEach((elm) => {
            points.push([elm.lat(), elm.lng()]);
          });
        });

        var dist = Math.floor(
          result.routes[0].legs.map(
            e => {
              return e.distance.value
            }
          )
            .reduce(
              (c, p) => c + p
            ) / 1000
        );

        const polyline = L.polyline(points, { color: 'yellow', opacity: 1 }).bindPopup((dist).toFixed(2) + ' km').addTo(this.map).openPopup();
        this.rulerPolyline = polyline;

        polyline.on("click", function (e) {
          //do nothing
        });
      }
    });
  }

  togglePadding() {
    this.zoomLock = !this.zoomLock;

    if (this.zoomLock) {
      this.polylineRefArray.forEach(polyline => {
        this.map.removeLayer(polyline.object);
      });

      this.polylineRefArray.forEach(polyline => {
        polyline.object.options.color = this.getRandomColor();
        polyline.object.options.offset = Math.floor(Math.random() * (10 + 10 + 1) - 10);
        this.map.addLayer(polyline.object);
      });
    } else {
      this.polylineRefArray.forEach(polyline => {
        this.map.removeLayer(polyline.object);
      });

      this.polylineRefArray.forEach(polyline => {
        polyline.object.options.color = '#fbb03b';
        polyline.object.options.offset = 0;
        this.map.addLayer(polyline.object);
      });
    }
  }

  fetchRoutePolyline(id): string {
    if (this.polylines) {
      var item = this.polylines.filter(elm => elm.RouteId === id);
      if (item[0]) return item[0].Polyline; else return 'NA';
    } else {
      this.snackbar('Routes still loading. Try again in a moment.')
    }
  }

  showTruckColors() {
    if (this.canProductColors === true) {
      this.showProductColors();
    }
    this.ngZone.run((n) => {
      if (!this.canTruckColors) {
        this.canTruckColors = true;
        this.polylineRefArray.forEach(elm => {
          var truck = this.trucks.filter(truck => truck.T_T_ID == elm.truck)[0];

          if (truck.color != null) {
            elm.object.setStyle({
              color: truck.color
            });
          }
        });
      } else {
        this.canTruckColors = false;
        this.polylineRefArray.forEach(elm => {
          elm.object.setStyle({
            color: '#fbb03b'
          });
        });
      }
    })
  }

  showProductColors() {
    if (this.canTruckColors === true) {
      this.showTruckColors();
    }
    this.ngZone.run((n) => {
      if (!this.canProductColors) {
        this.canProductColors = true;
        this.polylineRefArray.forEach(elm => {
          var product = this.products.filter(product => product.ProductId == elm.product)[0];

          if (product.color != null) {
            elm.object.setStyle({
              color: product.color
            });
          }
        });
      } else {
        this.canProductColors = false;
        this.polylineRefArray.forEach(elm => {
          elm.object.setStyle({
            color: '#fbb03b'
          });
        });
      }
    })
  }

  openOpportunityInDynamics(opportunityid: string) {
    window.open(`https://live2-routelinks.crm4.dynamics.com/main.aspx?app=d365default&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=${opportunityid}`, '_blank');
  }

  //Adds overlap to captured overlap table
  onOverlapSelect(elm) {
    var body = {
      "routeone": elm.route1,
      "routetwo": elm.route2,
      "accountone": elm.route1Ref.owner,
      "accounttwo": elm.route2Ref.owner,
      "distance": elm.distance,
      "overlapid": elm.RouteOverlapID
    };

    this.dbService.captureOverlap(body).subscribe(
      (res: any) => {
        //update overlap in GUI
        this.snackbar('Success - Overlap Captured');

        var temp = this.dataSource.data;
        temp[temp.indexOf(elm)].captured = res.opportunityid;
        this.dataSource.data = temp;
        this.changeDetectorRef.detectChanges();

        //update overlap in DB
        this.dbService.updateCaptureOverlap(
          {
            "opportunityid": res.opportunityid,
            "overlapid": elm.RouteOverlapID
          }
        ).subscribe(
          () => {
            this.snackbar('Success - Overlap Captured');
          }
        );

      }
    );
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.overlaps(this.currentPage , this.pageSize);
  }

  overlaps(currentPage :number , pageSize : number){

    if (this.dataSource) this.dataSource = new MatTableDataSource([]);
    this.changeDetectorRef.detectChanges();
    this.loadingOverlaps = true;

    this.canOverlapCalc = false;

    if (this.polylineRefArray.length > 0) {
          this.isCalculatingRoutes = true;
          this.totalOverlapDistance = 0;
          this.totalOverlaps = 0;
          this.canShowOverlaps = true;
    this.dbService.getoverlaps(currentPage, pageSize).subscribe((e: any) => {

      if (e.length == 0) this.snackbar('Error - No overlaps found');

      console.log('workerthreadsdata')
      this.totalRows = e.maxPage;
      this.currentPage = e.currentPage;
      //this.totalRows = e.maxPage;
      this.workerThread.postMessage({
        overlaps: e,
        ids: this.polylineRefIDArray,
        data: {
          routes: this.routes,
          trucks: this.trucks
        }
      });

      this.workerThread.onmessage = (e: any) => {
        var _routes: any[] = e.data.routes;

        this.totalOverlapDistance = e.data.distance.toFixed(2);
        this.totalOverlaps = _routes.length;
        var finalRoutes = _routes.sort((a, b) => parseInt(a.distance) < parseInt(b.distance) ? 1 : parseInt(a.distance) == parseInt(b.distance) ? 0 : -1).map(
          (route_: any) => {
            if (route_.route1Ref && route_.route2Ref) {
              if (this.isGuid(route_.route1Ref.commodity)) route_.route1Ref.commodity = this.replaceCommodityName(route_.route1Ref.commodity);
              if (this.isGuid(route_.route2Ref.commodity)) route_.route2Ref.commodity = this.replaceCommodityName(route_.route2Ref.commodity);

              if (this.isGuid(route_.route1Ref.trucktrailercategory)) route_.route1Ref.trucktrailercategory = this.replaceTruckCategoryName(route_.route1Ref.trucktrailercategory);
              if (this.isGuid(route_.route2Ref.trucktrailercategory)) route_.route2Ref.trucktrailercategory = this.replaceTruckCategoryName(route_.route2Ref.trucktrailercategory);

            } else {
              console.log('route_', route_)
            }
            return route_;
          }
        );

        this.findPolylineIntersections(finalRoutes);
      };
    });
  } else {
    this.snackbar('Error - No routes loaded');
  }
  }

}
