import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {fadeIn} from 'src/@treo/animations/fade'
@Component({
  selector: 'app-overlaps-popup',
  templateUrl: './overlaps-popup.component.html',
  styleUrls: ['./overlaps-popup.component.scss'],
  animations: [fadeIn]
})
export class OverlapsPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OverlapsPopupComponent>) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.dialogRef.close(1);
  }

}
