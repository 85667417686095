/* tslint:disable:max-line-length */
import { TreoNavigationItem } from "src/@treo/components/navigation";

export const defaultNavigation: any[] = [

    //navigation for admins
    {
        level: 0,
        items: [
            {
                id      : 'general',
                title   : 'General',
                subtitle: 'The day to day',
                type    : 'group',
                icon    : 'apps',
                children: [
                    {
                        id   : 'map',
                        title: 'Overlap Mining',
                        type : 'basic',
                        link : '/map'
                    }
                ]
            }
        ]
    },


    //Navigation for reps
    {
        level: 1,
        items: [
            {
                id      : 'general',
                title   : 'General',
                subtitle: 'The day to day',
                type    : 'group',
                icon    : 'apps',
                children: [

                    {
                        id   : 'map',
                        title: 'Overlap Mining',
                        type : 'basic',
                        link : '/map'
                    }
                ]
            }
        ]
    },

    //Navigation for customers
    {
        level: 2,
        items: [
            {
                id      : 'general',
                title   : 'Customer Portal',
                subtitle: '',
                type    : 'group',
                icon    : 'apps',
                children: [

                ]
            }
        ]
    },
];


export const compactNavigation: TreoNavigationItem[] = [
    {
        id      : 'starter',
        title   : 'Starter',
        type    : 'aside',
        icon    : 'apps',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: TreoNavigationItem[] = [
    {
        id   : 'starter.example',
        title: 'Example component',
        type : 'basic',
        icon : 'heroicons:chart-pie',
        link : '/example'
    },
    {
        id   : 'starter.dummy.1',
        title: 'Dummy menu item #1',
        icon : 'heroicons:calendar',
        type : 'basic'
    },
    {
        id   : 'starter.dummy.2',
        title: 'Dummy menu item #1',
        icon : 'heroicons:user-group',
        type : 'basic'
    }
];
export const horizontalNavigation: TreoNavigationItem[] = [
    {
        id      : 'starter',
        title   : 'Starter',
        type    : 'group',
        icon    : 'apps',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
