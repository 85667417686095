import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActionsService } from 'src/app/services/map/actions.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges {
  @Output('confirm') confirm = new EventEmitter<any>();
  hasRecievedData = false;
  counter: number = 0;
  counterTimer: any;
  counterTimer2: any;
  counterTimerInterval: number = 200;

  routesTotal = 0;
  routesTotalDistance = 0;
  oppTotal = 0;

  distanceSavings = 0;
  savings = 0;

  @Input('over') over: number = 0;
  @Input('overlaps') overlaps: number = 0;
  @Input('routes') routes: any[] = [];
  @Input('routesLength') routesLength: number = 0;
  @Input('routesDistance') routesDistance: number = 0;

  constructor(private actionService: ActionsService) {
    this.actionService.overlapCalcSubmitted.subscribe((data) => {
      var distance = 0;
      data.forEach((element: any) => {
        distance += element;
      });
      this.hasRecievedData = true;
      clearInterval(this.counterTimer);
      clearInterval(this.counterTimer2);

      this.overlaps = data.length;
      this.distanceSavings = Math.ceil(distance);
      this.savings = Math.ceil(this.distanceSavings * 15);

      this.routesTotal = this.routesLength;
      this.routesTotalDistance = Math.ceil(this.routesDistance / 1000);
    });
  }

  onConfirm() {
    this.confirm.emit('confirmed');
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.overlaps) {
      this.overlaps = changes.overlaps.currentValue;
    }
}

  ngOnInit(): void {
    this.counterTimer = setInterval(() => {
      if(this.routesLength >  this.routesTotal) {
        this.routesTotal += 1;
        if(this.routesDistance >  this.routesTotalDistance) {
          this.routesTotalDistance += Math.floor(Math.random() * 500) + 200;
        } else {
          this.routesTotalDistance = Math.ceil(this.routesDistance / 1000);
        }
      } else {
        this.routesTotal = this.routesLength;
      }
    }, 1000)
  }

}
