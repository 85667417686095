import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class DbService {
  endpoint = 'https://us-central1-routelinks.cloudfunctions.net/api/v1/';
  dynamics_endpoint = 'https://prod-227.westeurope.logic.azure.com:443/workflows/1da437955fa646ba8c7b7eaa95003e9c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZUDxDQ3XKqyvg2juUPhvdr4IkJmBGlEoU68tMP7xl24';
  cloudrun_endpoint = 'https://nsas-mplukse7cq-uc.a.run.app'
  configSuffix = '';
  public hasInitialized = false;

  constructor(private http: HttpClient) {
    if(environment.production == true) this.configSuffix = "";
    else this.configSuffix = '';
    this.hasInitialized = true;

  }

  bootstrapDb(isDev: boolean) {
  //  if(!isDev) this.configSuffix = 'Test';
    this.hasInitialized = true;
  }

  //Truck and Trailer CRUD Operations

  gettypetrucktrailercategory() {
    return this.http.get(this.endpoint + 'typetrucktrailercategories'+this.configSuffix);
  }

  gettypetrucktrailer() {
    return this.http.get(this.endpoint + 'typetrucktrailer'+this.configSuffix);
  }

  manualoverlap() {
    return this.http.post(this.endpoint + 'overlap', httpOptions);
  }

  //Commodity CRUD Operations
  getcommodity() {
    return this.http.get(this.endpoint + 'commodities'+this.configSuffix);
  }

  gettypecommoditycategory() {
    return this.http.get(this.endpoint + 'typecommoditycategories'+this.configSuffix);
  }

  gettypepackaging() {
    return this.http.get(this.endpoint + 'typepackaging'+this.configSuffix);
  }

    //Client CRUD Operations
    getClients() {
      return this.http.get(this.endpoint + 'getClients'+this.configSuffix);
    }

    createClient(Client) {
      let body = JSON.stringify(Client);
      return this.http.post(this.endpoint + 'addClients'+this.configSuffix, body, httpOptions);
    }

    updateClient(Client) {
      let body = JSON.stringify(Client);
      return this.http.put(this.endpoint + 'updateClients'+this.configSuffix, body, httpOptions);
    }

    deleteClient(Client) {
      let body = JSON.stringify(Client);
      return this.http.delete(this.endpoint + 'deleteClients'+this.configSuffix+'?id='+ Client['id']);
    }

    //Route CRUD Operations
    getRoutes() {
      return this.http.get('https://nsas-mplukse7cq-uc.a.run.app/getroutes');
    }

    getAccounts() {
      return this.http.get(this.endpoint + 'accounts'+this.configSuffix);
    }

    getPolylines() {
      return this.http.get(this.cloudrun_endpoint+'/getpolylines');
    }

    getoverlaps(currentPage : number , itemsPerPage : number) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("currentPage",currentPage);
      queryParams = queryParams.append("itemsPerPage",itemsPerPage);
      return this.http.get(this.cloudrun_endpoint+'/getoverlapspaged',{params:queryParams});
    }


    //User CRUD Operations
    loginUser(credentials) {
      let body = JSON.stringify(credentials);
      return this.http.post(this.endpoint + 'login'+this.configSuffix, body, httpOptions);
    }

    getUsers() {
      return this.http.get(this.endpoint + 'getUsers'+this.configSuffix);
    }

    createUser(User) {
      let body = JSON.stringify(User);
      return this.http.post(this.endpoint + 'addUsers'+this.configSuffix, body, httpOptions);
    }

    deleteOverlap(overlap) {
      let body = JSON.stringify(overlap);
      return this.http.post(this.endpoint + 'deleteoverlap'+this.configSuffix, body, httpOptions);
    }

    updateUser(User) {
      let body = JSON.stringify(User);
      return this.http.put(this.endpoint + 'updateUsers'+this.configSuffix, body, httpOptions);
    }

    deleteUser(User) {
      let body = JSON.stringify(User);

      return this.http.delete(this.endpoint + 'deleteUsers'+this.configSuffix+'?id='+ User['id']);
    }

     //Captured Overlap CRUD Operations

    getCapturedOverlaps(id) {
      return this.http.get(this.endpoint + 'getCapturedOverlaps'+this.configSuffix+'?id=' + id);
    }

    captureOverlap(overlap) {
      let body = overlap;
      console.log('captureOverlap', overlap)
      return this.http.post(this.dynamics_endpoint, body, httpOptions);
    }

    updateCaptureOverlap(overlap) {
      let body = overlap;
      return this.http.post(this.endpoint + 'overlapcaptured', body, httpOptions);
    }

    releaseOverlap(id, reason) {
      var date = new Date().toISOString().slice(0, 19).replace('T', ' ')
      return this.http.get(this.endpoint + 'releaseOverlap'+this.configSuffix+'?id=' + id +'&reason='+reason+'&created='+date);
    }


    manualOverlapCalculation() {
      let body = JSON.stringify({});
      return this.http.post('https://nsas-nmvl2exhda-uc.a.run.app/new-routes-calculate'+this.configSuffix, body, httpOptions);
    }

      //Consumer CRUD Operations
      getConsumers() {
        return this.http.get(this.endpoint + 'getconsumer'+this.configSuffix);
      }

      getConsumerTypes() {
        return this.http.get(this.endpoint + 'gettypeconsumer'+this.configSuffix);
      }

       //Quarries CRUD Operations
       getQuarries() {
        return this.http.get(this.endpoint + 'getquarry'+this.configSuffix);
      }

      getQuarryTypes() {
        return this.http.get(this.endpoint + 'gettypequarry'+this.configSuffix);
      }




}
