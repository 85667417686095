<div id="loaderChartCard">
  <div>
      <button *ngIf="hasRecievedData"
      (click)="onConfirm()"
      class="loaderBtn"
      color="primary"
       mat-flat-button>Proceed to Plot Opportunities</button>
  </div>
  <div class="loaderDivContainer">

      <div class="loaderDiv" style="margin-right: 50px;">
          <div style="margin-top: 50px;">
              <p>ROUTES FOUND</p>
              <h1 style="margin: auto;">{{routesTotal}}</h1>
              <p>({{routesTotalDistance}} KM)</p>
          </div>
      </div>

      <div class="loaderDiv" style="margin-right: 50px;">
          <div style="margin-top: 50px;">
              <p>OPPORTUNITIES FOUND</p>
              <h1 style="margin: auto;" *ngIf="overlaps">{{overlaps}}</h1>
              <h1 style="margin: auto;" *ngIf="!overlaps" class="loading"></h1>
          </div>
      </div>

      <div class="loaderDiv">
          <div style="margin-top: 50px;">
              <p>TOTAL SAVINGS</p>
              <h1 style="margin: auto;">{{distanceSavings}} KM</h1>
              <p>(R{{savings}})</p>
          </div>
      </div>

  </div>
</div>
