import { Component, OnInit, Inject } from '@angular/core';
import * as L from 'leaflet';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SearchMatrixPopupComponent } from '../search-matrix-popup/search-matrix-popup.component';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-view-overlap-map',
  templateUrl: './view-overlap-map.component.html',
  //styleUrls: ['./view-overlap-map.component.scss']
})
export class ViewOverlapMapComponent implements OnInit {
  map;

  overlapPolyline;
  routeOnePolyline;
  routeTwoPolyline;

  constructor(public mapDailog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<SearchMatrixPopupComponent>, private dataService: DataService) {}

  delete() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    console.log(this.data)

    //Prepare polylines for display
    var OID = this.data;
    if(OID) {
      var capturedOverlap = this.dataService.getCapturedOverlapById(OID);
      var routeIds = JSON.parse(capturedOverlap.RouteIDs);

      this.routeOnePolyline = google.maps.geometry.encoding.decodePath(
        this.dataService.getRouteById(routeIds[0]).polyline
      );
      this.routeTwoPolyline = google.maps.geometry.encoding.decodePath(
        this.dataService.getRouteById(routeIds[1]).polyline
      );
      this.overlapPolyline = this.dataService.getRouteOverlapById(capturedOverlap.RouteOverlapID).Polyline;
    }

    //Init map and consume polylines
    this.map = L.map('map', {
      center: [-25.791004, 28.303009],
      zoom: 10,
      zoomControl: false
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);


    //Prepare Routes
  var icon_start1 = L.icon({
    iconUrl: '../../../../../assets/icons/start-pin-s.png',
    iconSize:     [20, 25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
  });

  var icon_end1 = L.icon({
    iconUrl: '../../../../../assets/icons/end-pin-s.png',
    iconSize:     [20, 25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
  });

  var icon_start2 = L.icon({
    iconUrl: '../../../../../assets/icons/start-pin-s.png',
    iconSize:     [20, 25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
  });

  var icon_end2 = L.icon({
    iconUrl: '../../../../../assets/icons/end-pin-s.png',
    iconSize:     [20, 25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
  });

    const latlngs1 = this.routeOnePolyline;
    var displayLine1 = latlngs1.map(
      (coord) => {
        return [coord.lat(), coord.lng()]
      }
    );

    const latlngs2 = this.routeTwoPolyline;
    var displayLine2 = latlngs2.map(
      (coord) => {
        return [coord.lat(), coord.lng()]
      }
    );

    L.marker([latlngs1[0].lat(), latlngs1[0].lng()], {icon: icon_start1}).addTo(this.map);
    L.marker([latlngs1[latlngs1.length - 1].lat(), latlngs1[latlngs1.length - 1].lng()], {icon: icon_end1}).addTo(this.map);

    L.marker([latlngs2[0].lat(), latlngs2[0].lng()], {icon: icon_start2}).addTo(this.map);
    L.marker([latlngs2[latlngs2.length - 1].lat(), latlngs2[latlngs2.length - 1].lng()], {icon: icon_end2}).addTo(this.map);

    const polyline1 = L.polyline(displayLine1, {className: 'animated_polyline'}).addTo(this.map);
    const polyline2 = L.polyline(displayLine2, {className: 'animated_polyline'}).addTo(this.map);
    const overlapPolyline = L.polyline(JSON.parse(this.overlapPolyline), {color: 'red', weight: 10}).addTo(this.map);

    this.map.fitBounds(overlapPolyline.getBounds());
    this.map.setZoom(9);
  }

}
