<h1 mat-dialog-title>Calculate Overlaps</h1>
<p mat-dialog-subtitle>This operation finds overlapping routes and calculates cost-saving data. This operation will take a few moments.</p>
<div mat-dialog-content class="filterContainer">
    <img
     class="overlap-img"
     @fadeIn
     src="../../../../../assets/images/overlap.svg">
</div>
<div mat-dialog-actions style="padding-bottom: 25px !important;">
    <button mat-button mat-dialog-close>Close</button>
    <button (click)="onSubmit()" style="background: #fbb03b !important;"
     mat-flat-button color="primary">Proceed</button>
</div>
