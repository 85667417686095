import { NgModule } from '@angular/core';
import { TreoDrawerModule } from 'src/@treo/components/drawer/drawer.module';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { EmptyLayoutModule } from 'src/app/layout/layouts/empty/empty.module';
import { CenteredLayoutModule } from 'src/app/layout/layouts/horizontal/centered/centered.module';
import { EnterpriseLayoutModule } from 'src/app/layout/layouts/horizontal/enterprise/enterprise.module';
import { MaterialLayoutModule } from 'src/app/layout/layouts/horizontal/material/material.module';
import { ModernLayoutModule } from 'src/app/layout/layouts/horizontal/modern/modern.module';
import { BasicLayoutModule } from './layouts/verticel/basic/basic.module';
import { ClassicLayoutModule } from './layouts/verticel/classic/classic.module';
import { ClassyLayoutModule } from './layouts/verticel/classy/classy.module';
import { CompactLayoutModule } from './layouts/verticel/compact/compact.module';
import { DenseLayoutModule } from './layouts/verticel/dense/dense.module';
import { FuturisticLayoutModule } from './layouts/verticel/futuristic/futuristic.module';
import { ThinLayoutModule } from './layouts/verticel/thin/thin.module';
import { SharedModule } from '../shared/shared.module';

const modules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    BasicLayoutModule,
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports     : [
        TreoDrawerModule,
        SharedModule,
        ...modules
    ],
    exports     : [
        ...modules
    ]
})
export class LayoutModule
{
}
