import { Injectable } from '@angular/core';
import { DbService } from '../db/db.service';
import { Observable } from 'rxjs';
import { TreoSplashScreenService } from 'src/@treo/services/splash-screen/splash-screen.service';
import { CapturedActionService } from '../captured-action.service';
import { customerTypes } from '../../data/mock/pages/help-center/data';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private trucktrailer = [];
  private trucktrailercategory = [];
  private commodity = [];
  private commoditycategory = [];
  private accounts = [];
  private packaging = [];

  private routes = [];
  private polylines = [];
  private users = [];
  private overlaps = [];
  private capturedOverlaps = [];
  private customerTypes = customerTypes;
  private quarries = [];
  private quarryTypes = [];
  private consumers = [];
  private consumerTypes = [];
  public CID = -1;
  public UID = -1;
  public hasLoaded = false;
  constructor(private db: DbService, private capturedOverlapService: CapturedActionService,
    private splashService: TreoSplashScreenService) {

      var token = localStorage.getItem('access_token');
      if(token != null) {

        var hasInitialized = this.db.hasInitialized;
        if(!hasInitialized) {
          var initInterval = setInterval(
            () => {

              var hasNowInitialized = this.db.hasInitialized;
              if(hasNowInitialized) {
                this.loadDbData();
                clearInterval(initInterval);
              }
            }, 3000
          );
        } else {
          this.loadDbData();
        }

      } else  {
        this.loadDbData();
        //this.splashService.hide();
      }
  }

  public loadDbData() {

    //Fetch Truck & Trailer
    this.db.gettypetrucktrailer().subscribe( (res: any) => {
      this.trucktrailer = res.data;
      this.checkIfAllLoaded();
      this.splashService.assetLoaded('Truck Types');
    });

    //Fetch Truck & Trailer category
    this.db.gettypetrucktrailercategory().subscribe( (res: any) => {
      this.trucktrailercategory = res.data;
      this.checkIfAllLoaded();
      this.splashService.assetLoaded('Truck Types');
    });

   //Fetch products
    this.db.getcommodity().subscribe( (res: any) => {
    this.commodity = res.data;
    this.checkIfAllLoaded();
    this.splashService.assetLoaded('Commodity');
    });

     //Fetch product category
     this.db.gettypecommoditycategory().subscribe( (res: any) => {
      this.commoditycategory = res.data;
      this.checkIfAllLoaded();
      this.splashService.assetLoaded('Commodity Category');
    });

    //Fetch overlaps
    this.db.getoverlaps(1, 5).subscribe( (res: any) => {
      console.log("data 1");
      this.overlaps = res;
      this.checkIfAllLoaded();
      this.splashService.assetLoaded('Overlaps');
    });

   //Fetch routes
   this.db.getRoutes().subscribe( (res: any) => {
    this.routes = res;
    this.checkIfAllLoaded();
    this.splashService.assetLoaded('Routes');
  });

   //Fetch accounts
   this.db.getAccounts().subscribe( (res: any) => {
    this.accounts = res.data;
    this.checkIfAllLoaded();
    this.splashService.assetLoaded('Accounts');
  });

  //Fetch Packaging
  this.db.gettypepackaging().subscribe( (res: any) => {
    this.packaging = res.data;
    this.checkIfAllLoaded();
    this.splashService.assetLoaded('Packaging');
  });

  }

  checkIfAllLoaded() {
    if( this.routes.length > 0 &&  this.accounts.length > 0
      && this.commodity.length > 0 && this.commoditycategory.length > 0
      && this.trucktrailercategory.length > 0 && this.trucktrailer.length > 0
      && this.packaging.length > 0 ) {
      this.splashService.hide();
      //hide loader once loaded
      var hideInterval = setInterval(
        () => {
          document.getElementById('loader-container').style.display = 'none';
          clearInterval(hideInterval);
        }, 2000
      );
      this.hasLoaded = true;
    }
  }

  public showSplashScreen() {
  }

  public getpolylines = Observable.create((observer: any) => {
    if(!this.polylines || this.polylines.length == 0){
      this.db.getPolylines().subscribe( polylines => {
        observer.next(polylines);
      });
    } else {
      observer.next(this.polylines);
    }
  });

  public getoverlaps = Observable.create((observer: any) => {
    console.log("data 2");
    if(!this.overlaps || this.overlaps.length == 0){
      this.db.getoverlaps(1, 5).subscribe( overlaps => {
        observer.next(overlaps);
      });
    } else {
      observer.next(this.overlaps);
    }
  });

  public getaccounts = Observable.create((observer: any) => {
    if(!this.accounts || this.accounts.length == 0){
      this.db.getAccounts().subscribe( (accounts: any) => {
        observer.next(accounts.data);
      });
    } else {
      observer.next(this.accounts);
    }
  });

  public getpackaging = Observable.create((observer: any) => {
    if(!this.packaging || this.packaging.length == 0){
      this.db.gettypepackaging().subscribe( (packaging: any) => {
        observer.next(packaging.data);
      });
    } else {
      observer.next(this.packaging);
    }
  });

  public gettrucktrailercategories = Observable.create((observer: any) => {
    if(!this.trucktrailercategory || this.trucktrailercategory.length == 0){
      this.db.gettypetrucktrailercategory().subscribe( (trucktrailercategory: any) => {
        observer.next(trucktrailercategory.data);
      });
    } else {
      observer.next(this.trucktrailercategory);
    }
  });

  public getroutes = Observable.create((observer: any) => {
    if(!this.routes || this.routes.length == 0){
      this.db.getRoutes().subscribe( (routes: any) => {
        this.routes = routes;
        observer.next(routes);
      });
    } else {
      observer.next(this.routes);
    }
  });

  public getcommodities = Observable.create((observer: any) => {
    if(!this.commodity || this.commodity.length == 0){
      this.db.getcommodity().subscribe( (commodities: any) => {
        observer.next(commodities.data);
      });
    } else {
      observer.next(this.commodity);
    }
  });

  public getcommoditiescategory = Observable.create((observer: any) => {
    if(!this.commoditycategory || this.commoditycategory.length == 0){
      this.db.gettypecommoditycategory().subscribe( (commoditiescategories: any) => {
        observer.next(commoditiescategories.data);
      });
    } else {
      observer.next(this.commoditycategory);
    }
  });

  public gettypetrucktrailer = Observable.create((observer: any) => {
    if(!this.trucktrailer || this.trucktrailer.length == 0){
      this.db.gettypetrucktrailer().subscribe( (trucks: any) => {
        observer.next(trucks.data);
      });
    } else {
      observer.next(this.trucktrailer);
    }
  });

  public gettypetrucktrailercategory = Observable.create((observer: any) => {
    if(!this.trucktrailercategory || this.trucktrailercategory.length == 0){
      this.db.gettypetrucktrailercategory().subscribe( trucks => {
        observer.next(trucks);
      });
    } else {
      observer.next(this.trucktrailercategory);
    }
  });

  public getCustomerTypes() {
    return this.customerTypes;
  }

  public getQuarries: Observable<any> = Observable.create((observer: any) => {
    if(!this.quarries || this.quarries.length == 0) {
      this.db.getQuarries().subscribe( quarries => {
        observer.next(quarries);
      });
    } else {
      observer.next(this.quarries);
    }
  });

  public getQuarryTypes: Observable<any> = Observable.create((observer: any) => {
    if(!this.quarryTypes || this.quarryTypes.length == 0) {
      this.db.getQuarryTypes().subscribe( quarryTypes => {
        observer.next(quarryTypes);
      });
    } else {
      observer.next(this.quarryTypes);
    }
  });

  public getConsumers: Observable<any> = Observable.create((observer: any) => {
    if(!this.consumers || this.consumers.length == 0) {
      this.db.getConsumers().subscribe( consumers => {
        observer.next(consumers);
      });
    } else {
      observer.next(this.consumers);
    }
  });

  public getConsumerTypes: Observable<any> = Observable.create((observer: any) => {
    if(!this.consumerTypes || this.consumerTypes.length == 0) {
      this.db.getConsumerTypes().subscribe( consumerTypes => {
        observer.next(consumerTypes);
      });
    } else {
      observer.next(this.consumerTypes);
    }
  });


  public getcommodity = Observable.create((observer: any) => {
    if(!this.commodity || this.commodity.length == 0) {
      this.db.getcommodity().subscribe( (products: any) => {
        this.commodity = products;

        observer.next(products);
      });
    } else {
      observer.next(this.commodity);
    }
  });


  public getOverlaps = Observable.create((observer: any) => {
    console.log("data 3");
    if(!this.overlaps || this.overlaps.length == 0) {
      this.db.getoverlaps(1,5).subscribe( (overlaps: any) => {
        this.overlaps = overlaps;
        observer.next(overlaps);
      });
    } else {
      observer.next(this.overlaps);
    }
  });

  public setOverlaps(e) {
    this.overlaps.push(e);
  }

  public resetOverlaps() {
    this.overlaps = [];
  }

  public getUsers = Observable.create((observer: any) => {
    if(!this.users || this.users.length == 0) {
      this.db.getUsers().subscribe( users => {
        observer.next(users);
      });
    } else {
      observer.next(this.users);
    }
  });


  public getCapturedOverlaps = Observable.create((observer: any) => {
    if(!this.capturedOverlaps || this.capturedOverlaps.length == 0) {
      this.db.getCapturedOverlaps(1).subscribe( overlaps => {
        observer.next(overlaps);
      });
    } else {
      observer.next(this.capturedOverlaps);
    }
  });

/*   public getRoutesPolylines = Observable.create((observer) => {
    if(!this.polylines || this.polylines.length == 0) {
      this.db.getRoutesPolylines().subscribe( polylines => {
        observer.next(polylines);
      });
    } else {
      observer.next(this.polylines);
    }
  }); */

  public addRoute(route, ids) {
    route.RouteId = ids.routeID;

    this.routes.push(route);
  }

  public getEncodedPolyline(RouteID) {
    return this.routes.filter(x => x.RouteId === RouteID)[0].polyline;
  }

  public addCapturedOverlap(overlap) {
    this.capturedOverlaps.push(overlap);
  }


  public getRouteById(id): any {
    var result = null;
    var index = this.routes.map(e => {return e.RouteId})
                .indexOf(id);

    if(index > -1) result = this.routes[index];
    return result;
  }


  public getUserByEmail(email): any {
    var result = null;
    var index = this.users
                .filter(
                  e => e.Email == email
                );

    if(index.length > 0) result = index[0];
    return result;
  }

  public getRouteOverlapById(id): any {
    var result = null;
    var index = this.overlaps.map(e => {return e.ID})
                .indexOf(id);

    if(index > -1) result = this.overlaps[index];
    return result;
  }

  public getCapturedOverlapById(id): any {
    var result = null;
    var index = this.capturedOverlaps.map(e => {return e.ID})
                .indexOf(id);

    if(index > -1) result = this.capturedOverlaps[index];
    return result;
  }


  public getProductById(id): any {
    var result = null;
    var index = this.commodity.map(e => {return e.ProductId})
                .indexOf(id);

    if(index > -1) result = this.commodity[index];
    return result;
  }

  public getCustomerTypeById(id): any {
    var result = null;
    var index = this.customerTypes.map(e => {return e.ID})
                .indexOf(id);

    if(index > -1) result = this.customerTypes[index];
    return result;
  }


  public getNumberOpportunities(): number {
    if(this.capturedOverlaps.length == 0) {
      this.getCapturedOverlaps
      .subscribe(
        (cs) => {
          this.capturedOverlaps = cs;
        }
      );
    }
    return this.capturedOverlaps.length;
  }

  public getOpportunitiesOverlapDistance(): number {
    return this.capturedOverlaps.map(
      e => {
        var overlap = this.getRouteOverlapById(e.ID);
        return overlap.OverlapDistance;
      }
    ).reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
  }



  public updateCapturedOverlap(elm) {
    var index = this.capturedOverlaps.map(e => {return e.ID})
                .indexOf(elm.ID);
    if(index > -1) {
      this.capturedOverlaps[index] = elm;
    }
  }

  public updateRoute(route) {
    let index = this.routes.map(e => {return e.RouteId}).indexOf(route.RouteId);

    if(index >= 0) {
      this.routes[index] = route;
    }
  }

  public releaseOverlap(id, reason) {
    let overlap = this.capturedOverlaps.filter(elm => elm.ID == id);

    if(overlap[0]) {
      let index = this.capturedOverlaps.indexOf(overlap[0]);
      this.capturedOverlaps[index].Released = 1;
      this.capturedOverlaps[index].ReasonReleased = reason;
    }
  }

  public addCapturedReleasedOverlap(OID) {
    let index = this.capturedOverlaps.map(function(x) {return x.RouteOverlapID; }).indexOf(OID);
    if(this.capturedOverlaps[index]) {
      this.capturedOverlaps[index].Released = 0;
      this.capturedOverlaps[index].ReasonReleased = '';
    }
  }
}
