import { Component } from '@angular/core';
import { DataService } from './services/data/data.service';
import { DbService } from './services/db/db.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, RouterLinkWithHref } from '@angular/router';
@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(private dataService: DataService,
      private dbService: DbService,
      private link: RouterLinkWithHref, private route: ActivatedRoute)
    {
        this.route.queryParamMap.subscribe(queryParams => {
            this.link.queryParams = Object.assign({}, this.route.snapshot.queryParams);
        });
        this.dbService.bootstrapDb(environment.production);
        this.dataService.checkIfAllLoaded();
    }

}

