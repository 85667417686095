import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterLinkWithHref, RouterModule } from '@angular/router';
import { TreoModule } from "src/@treo";
import { TreoConfigModule } from "src/@treo/services/config/config.module";
import { TreoMockApiModule } from "src/@treo/lib/mock-api/mock-api.module";
import { CoreModule } from 'src/app/core/core.module';
import { appConfig } from 'src/app/core/config/app.config';
import { mockDataServices } from 'src/app/data/mock';
import { LayoutModule } from 'src/app/layout/layout.module';
import { AppComponent } from "./app.component";
import { appRoutes } from "./app-routing.module";

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatTableExporterModule } from 'mat-table-exporter';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DragDropModule } from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import { NgApexchartsModule } from "ng-apexcharts";
import { MapComponent } from "./modules/admin/map/map/map.component";
import { SearchMatrixPopupComponent } from "./modules/admin/map/search-matrix-popup/search-matrix-popup.component";
import { OverlapsPopupComponent } from "./modules/admin/map/overlaps-popup/overlaps-popup.component";
//import 'hammerjs';
import { DbService } from './services/db/db.service';
import {MatPaginatorModule} from '@angular/material/paginator';
import { LoaderComponent } from "./modules/admin/map/loader/loader.component";
import { datetimePipe } from './pipes/datetime.pipe';
import { DataService } from './services/data/data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { SignaturePadModule } from 'angular2-signaturepad';
import { DesignationPipe } from './pipes/designation.pipe';
import { ViewOverlapMapComponent } from './modules/admin/map/view-overlap-map/view-overlap-map.component';
import { environment } from "src/environments/environment";

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy       : PreloadAllModules,
    paramsInheritanceStrategy: 'always'
};

@NgModule({
    declarations: [
        AppComponent,
        MapComponent,
        SearchMatrixPopupComponent,
        OverlapsPopupComponent,
        LoaderComponent,
        //datetimePipe,
        DesignationPipe,
         ViewOverlapMapComponent
    ],
    imports     : [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
       // SignaturePadModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockDataServices),
        NgApexchartsModule,
        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules

        //Angular Material components
        MatSliderModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,
        MatDialogModule,
        MatChipsModule,
        MatMenuModule,
        MatTooltipModule,
        DragDropModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatTabsModule,
        MatSortModule,
        MatTableExporterModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule
    ],
    bootstrap   : [
        AppComponent,
        DbService
    ],
    providers: [
        DbService,
        MatDatepickerModule,
        MatNativeDateModule,
        DataService,
        RouterLinkWithHref
    ]
})
export class AppModule
{
}
