<div id="map-container">
  <div id="map" [style.width]="mapWidth">
    <button mat-icon-button id="toggleMapBtn" (click)="toggleMapSize()">
      <mat-icon>switch_right</mat-icon>
    </button>
  </div>
  <div id="map-data-container" [style.width]="overlapWidth" @Fading>
    <div id="routeDataWindow">
      <mat-icon class="overlaps-icon" style="text-align: center;position: relative;top: 120px;left: 49%;right: 50%;"
        [svgIcon]="'no_sim'"></mat-icon>
      <p style="text-align: center; position: relative; top: 120px; color: #64748b;font-size: 12px;font-weight: 500;">
        Click a Route Marker to View Information</p>

    </div>
    <div>
      <button mat-flat-button id="alternativeRoutesButton" *ngIf="canShowAlternatives == true"
        (click)="displayAlternativeRoutes()">View Alternatives</button>
    </div>

    <div style="height: 55%; width: 100%;">
      <div id="overlapsContainer">
        <div class="overlap_table">

          <div *ngIf="loadingOverlaps == true" class="list  preloader-body">
            <div class="items">
              <div class="item pre line"></div>
              <div class="item pre line"></div>
              <div class="item pre line"></div>
            </div>
          </div>

          <table mat-table style="width: 100% !important; margin: auto;" [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="ID">
              <th mat-header-cell *matHeaderCellDef
                style="width: 80px;font-size: 11px;padding-left: 20px !important;box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);">
                Overlap </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{ 'capturedRow': element.captured != null }"
                style="font-size: 11px;padding-left: 20px !important;">{{element.RouteOverlapID}} </td>
            </ng-container>

            <ng-container matColumnDef="Route One">
              <th mat-header-cell *matHeaderCellDef
                style="padding-left: 15px;font-size: 11px;box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);">
                Routes Information</th>
              <td mat-cell style="padding-left: 15px;font-size: 11px;" *matCellDef="let element"
                [ngClass]="{ 'capturedRow': element.captured != null }">
                <div style="display: block;margin: 5px 0;">

                  <!-- route one info -->
                  <div class="route-stats-container-border" style="padding-bottom: 0;">
                    <div style="display: flex; width: 100%;">
                      <div [matTooltip]="element.route1Ref.name" style="width: 33%" class="padded-route-stat"
                        [ngClass]="{ 'capturedRowText': element.captured != null }">
                        <mat-icon class="overlaps-icon" [svgIcon]="'directions'"></mat-icon> {{element.route1Ref.name}}
                      </div>
                      <div [matTooltip]="element.route1Ref.commodity" style="width: 33%;" class="padded-route-stat"
                        [ngClass]="{ 'capturedRowText': element.captured != null }">
                        <mat-icon class="overlaps-icon" [svgIcon]="'category'"></mat-icon>
                        {{element.route1Ref.commodity}}
                      </div>
                      <div [matTooltip]="element.route1Ref.trucktrailertype" style="width: 33%;"
                        class="padded-route-stat" [ngClass]="{ 'capturedRowText': element.captured != null }">
                        <mat-icon class="overlaps-icon" [svgIcon]="'tram'"></mat-icon>
                        {{element.route1Ref.trucktrailertype}}
                      </div>
                    </div>
                  </div>

                  <!-- route two info -->
                  <div class="route-stats-container-border">
                    <div style="display: flex; width: 100%;">
                      <div [matTooltip]="element.route2Ref.name" style="width: 33%" class="padded-route-stat"
                        [ngClass]="{ 'capturedRowText': element.captured != null }">
                        <mat-icon class="overlaps-icon" [svgIcon]="'directions'"></mat-icon> {{element.route2Ref.name}}
                      </div>
                      <div [matTooltip]="element.route2Ref.commodity" style="width: 33%;" class="padded-route-stat"
                        [ngClass]="{ 'capturedRowText': element.captured != null }">
                        <mat-icon class="overlaps-icon" [svgIcon]="'category'"></mat-icon>
                        {{element.route2Ref.commodity}}
                      </div>
                      <div [matTooltip]="element.route2Ref.trucktrailertype" style="width: 33%;"
                        class="padded-route-stat" [ngClass]="{ 'capturedRowText': element.captured != null }">
                        <mat-icon class="overlaps-icon" [svgIcon]="'tram'"></mat-icon>
                        {{element.route2Ref.trucktrailertype}}
                      </div>
                    </div>
                  </div>

                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Distance">
              <th mat-header-cell mat-sort-header *matHeaderCellDef
                style="width: 80px; padding-left: 15px;font-size: 11px;"> Distance </th>
              <td mat-cell style="padding-left: 15px;font-size: 11px;" *matCellDef="let element"
                [ngClass]="{ 'capturedRow': element.captured != null }"> {{element.distance | number:'1.0-0'}} KM</td>
            </ng-container>

            <ng-container matColumnDef="Savings">
              <th mat-header-cell mat-sort-header *matHeaderCellDef
                style="width: 80px; padding-left: 15px;font-size: 11px;"> Savings </th>
              <td mat-cell style="padding-left: 15px;font-size: 11px;" *matCellDef="let element"
                [ngClass]="{ 'capturedRow': element.captured != null }" class="overflowCell">R {{element.savings |
                number:'2.0-0'}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef style="width: 15px; padding-left: 15px;font-size: 11px;"> Actions
              </th>
              <td mat-cell style="padding-left: 15px;font-size: 11px;text-overflow: clip !important;"
                *matCellDef="let element" [ngClass]="{ 'capturedRow': element.captured != null }" class="overflowCell">
                <button mat-icon-button [matMenuTriggerFor]="menuAction">
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menuAction="matMenu">
                  <button mat-menu-item (click)="highlightOverlap(element)">View</button>
                  <button mat-menu-item [disabled]="element.captured != null" (click)="onOverlapSelect(element)">Capture
                    Opportunity</button>
<!--                   <button mat-menu-item *ngIf="element.captured != null"
                    (click)="openOpportunityInDynamics(element.captured)">View on Dynamics</button> -->
                  <button mat-menu-item [matMenuTriggerFor]="removeOptions">Remove</button>
                  <mat-menu #removeOptions="matMenu">
                    <button mat-menu-item (click)="removeoverlap(element, 'Wrong Direction')">Wrong Direction</button>
                    <button mat-menu-item (click)="removeoverlap(element, 'Likely Optimised')">Likely Optimised</button>
                    <button mat-menu-item (click)="removeoverlap(element, 'Mismatch')">Mismatch</button>
                    <button mat-menu-item (click)="removeoverlap(element, 'Insufficient Overlap')">Insufficient Overlap</button>
                  </mat-menu>
                </mat-menu>

                <mat-icon *ngIf="element.released == true"
                  style="position: relative; bottom: 1px; color: #F44336; left: -5px;font-size: 20px;"
                  [matTooltip]="element.releaseReason">
                  flag
                </mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedRow = row"
              [ngClass]="{ 'selectedRow': row == selectedRow }">
            </tr>

          </table>
          <!-- <mat-paginator style="position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;" [pageSizeOptions]="[5,25,50]"></mat-paginator> -->

      <mat-paginator style="position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;" #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="colorToggle">
  <button class="colorButton" style="margin-bottom: 0;margin-top: 6px;font-weight: bold;" matTooltip="Show Truck Types"
    [ngClass]="{'colorToggleBtn': canTruckColors === true}" (click)="showTruckColors()">T</button>
  <button class="colorButton" style="margin-top: -11px;font-weight: bold;" matTooltip="Show Commodity Types"
    [ngClass]="{'colorToggleBtn': canProductColors === true}" (click)="showProductColors()">P</button>


</div>



<div id="drawRoutesContainer" cdkDrag *ngIf="canShowDrawnRoutes">
  <div class="overlapTableHeader">
    <div style="display: grid;width: 22%;padding-left: 10px;">
      <h4 style="padding-left: 15px;margin-bottom: 0;margin-top: 10px;">Route Selection</h4>
      <p style="padding-left: 15px;position: relative; top: -10px;">Click on a route to highlight</p>
    </div>

    <div style="display: flex; width: 50%; text-align: center;">

      <div class="stat_container" style="width: 145px !important; display: flex !important;">
        <p>Selected</p>
        <p style="margin-left: 10px;" *ngIf="drawResultRoutes.length > 0">{{drawResultRoutes.length}}</p>
        <h4 style="margin: auto;" *ngIf="drawResultRoutes.length == 0" class="loading"></h4>
      </div>

    </div>
  </div>
  <button class="closeButtonDiv"
    style="background: none !important; border-radius: 50% !important;width: 45px !important;position: absolute;right: 0; top: 15px;"
    (click)="closeOverlapTable()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <ul class="overlapList" style="margin-top: 15px !important;">
    <li *ngFor="let overlap of drawResultRoutes; let i = index;" class="drawnRouteListItem"
      style="background: none !important;" (click)="onDrawnRouteClick(overlap)">

      <table [ngClass]="{'highlightedOppTableItem': overlap.isHighlighted === true}">
        <tr style="background: #cdcdcd;">
          <th class="overflowCell smCell">OID</th>
          <th class="overflowCell lgCell">Pickup Client</th>
          <th class="overflowCell lgCell">Dropoff Client</th>
          <th class="overflowCell lgCell">Product</th>
          <th class="overflowCell lgCell">Truck Type</th>
          <th class="overflowCell lgCell">Route Distance</th>
        </tr>
        <tr>
          <th class="overflowCell smCell">{{overlap.id}} </th>
          <th class="overflowCell lgCell" [matTooltip]="overlap.pickupC">{{overlap.pickupC}} </th>
          <th class="overflowCell lgCell" [matTooltip]="overlap.dropoffC">{{overlap.dropoffC}} </th>
          <th class="overflowCell lgCell" [matTooltip]="overlap.product">{{overlap.product}} </th>
          <th class="overflowCell lgCell">{{overlap.truck}} </th>
          <th class="overflowCell lgCell">{{overlap.distance}} Km </th>
        </tr>
      </table>
    </li>
  </ul>
</div>
